import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
//ces
export default new Vuex.Store({
    state: {
        token: "jijij",
        notice: {},//保存用户在notice点击的选项
    },
    getters: {
        getNotice(state) {
            return state.notice
        }
    },
    mutations: {
        setToken(payload) {
            this.token = payload.token;
        },
        setNotice(payload) {
            this.notice = payload.notice;
        }
    },
    actions: {},
    modules: {},
});
