<template>
  <!-- /melhores-cassinos-online -->
  <div id="app">
    <Header></Header>
    <router-view/>
    <Footer></Footer>
    <ToTop></ToTop>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import ToTop from "./components/ToTop.vue";

export default {
  components: {
    Header,
    Footer,
    ToTop,
  },

  data() {
    return {};
  },
};
</script>
<style>
#app {
  margin: 0;
  padding: 0;
}
</style>
