export function searchByTitle(query) {
    const queryLower = query.toLowerCase(); // 将查询转换为小写以实现不区分大小写的匹配

    // 使用 reduce 方法来构建新的 datas 对象
    const filteredDatas = Object.keys(datas).reduce((acc, key) => {
        // 过滤每个分类中的数据
        const filteredItems = datas[key].filter(
            (item) =>
                item.title.toLowerCase().includes(queryLower) ||
                item.src.toLowerCase().includes(queryLower)
        );

        if (filteredItems.length > 0) {
            acc[key] = filteredItems; // 只有当有匹配的项时才添加到结果对象中
        }

        return acc;
    }, {});

    return filteredDatas;
}

const datas = {
    Análises: [
        {
            src: "/reviews/joyaCasino",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Joya",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/winz",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Winz",
            content: "28 de maio de 2024",
            flg: 0,
        },
        {
            src: "/reviews/citobet",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Citobet",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/smokace",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "SmokAce",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/bet-o-bet",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "BetoBet",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/31bet",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "31bet",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/wazamba",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Wazamba",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/lilibet",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "LiliBet",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/reviews/bet24-7",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Bet24/7",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/brazino-777",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Brazino 777",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/betano",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Betano",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/bc-game",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "BC.Game",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/blaze",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Blaze",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/betsson",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Betsson",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/galera-bet",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Galera.bet",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/reviews/casino-estrella",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Casino Estrella",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/casigo",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "CasiGO",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/casimba",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Casimba",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/platin-casino",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Platin Casino",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/vegaz-casino",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Vegaz Casino",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/monro-casino",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Monro Casino",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/horus-casino",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Horus Casino",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/reviews/depositwin",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "DepositWin",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/reviews/gratowin",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "GratoWin",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/reviews/let-s-go-casino",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Let´s Go Casino",
            content: "4 de junho de 2024",
            flg: 0,
        },

        {
            src: "/reviews/joiabet",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "JoiaBet",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/jackpotcity-casino",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "JackpotCity Casino",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/reviews/starda",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Starda",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/ivibet",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Ivibet",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/rocketpot",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Rocketpot",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/24slots",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "24Slots",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/reviews/kto",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "KTO",
            content: "3 de junho de 2024",
            flg: 0,
        },

        {
            src: "/reviews/lucky-star",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Lucky Star",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/reviews/lvbet",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "LVBet",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/leovegas",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Leovegas",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/melbet",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "MelBet",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/qbet",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Qbet",
            content: "4 de junho de 2024",
            flg: 0,
        },
        {
            src: "/reviews/megapari",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "MegaPari",
            content: "22 de maio de 2024",
            flg: 0,
        },

        {
            src: "/reviews/novibet",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Novibet",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/reviews/pikebit",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Pikebit",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/reviews/parimatch",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Parimatch",
            content: "3 de junho de 2024",
            flg: 0,
        },

        {
            src: "/reviews/windetta",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Windetta",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/reviews/rivalo",
            img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Rivalo",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/reviews/sapphirebet",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "SapphireBet",
            content: "13 de maio de 2024",
            flg: 0,
        },
        {
            src: "/reviews/stake",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Stake",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/reviews/vulkanbet",
            img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
            title: "Vulkanbet",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
    ],

    Páginas: [
        {
            src: "/cassino-ao-vivo",
            img: null,
            title: "Cassino Ao Vivo",
            content: "23 de maio de 2024",
            flg: 0,
        },
        {
            src: "/slots-com-alto-rtp",
            img: "https://www.datocms-assets.com/102406/1689963022-slots-de-cassino-rtp-alto.jpg",
            title: "Slots Com Alto RTP",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/apostas",
            img: null,
            title: "Sites de Apostas",
            content: "15 de maio de 2024",
            flg: 0,
        },
        {
            src: "/crash",
            img: null,
            title: "Crash Games Aposta",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/apostas/sites-de-apostas-com-skrill", //无页面 /完成
            img: null,
            title: "Melhores sites de apostas com Skrill em 2024",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/apostas/sites-de-apostas-que-aceitam-transferencia-bancaria",
            img: null,
            title: "Melhores sites de apostas que aceitam transferência bancária",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/apostas/sites-de-apostas-que-aceitam-neteller",
            img: null,
            title: "Melhores sites de apostas que aceitam Neteller",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/apostas/sites-de-apostas-que-aceitam-cartao-de-credito",
            img: null,
            title: "Melhores sites de apostas que aceitam cartão de crédito",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/apostas/sites-de-apostas-que-aceitam-pay4fun", //无页面/完成
            img: null,
            title: "Melhores sites de apostas que aceitam Pay4fun",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/cassino-ao-vivo/blackjack-online",
            img: null,
            title: "Blackjack Online do Brasil - Melhores Cassinos Com blackjack",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/bonus/cadastro",
            img: null,
            title: "Bônus de cassino cadastro",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/cassino-ao-vivo/baccarat-online",
            img: null,
            title: "Baccarat Online",
            content: "30 de abril de 2024",
            flg: 0,
        },
        {
            src: "/cassino-ao-vivo/roleta-brasileira",
            img: null,
            title: "Roleta Brasileira",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/cassino-ao-vivo/mega-fire-blaze-roulette",
            img: null,
            title: "Mega Fire Blaze Roulette",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/gameshows/live-mega-ball",
            img: "https://www.datocms-assets.com/102406/1689958705-mega-ball-live-online.jpg",
            title: "Live Mega Ball",
            content: "30 de abril de 2024",
            flg: 0,
        },
        {
            src: "/bonus",
            img: null,
            title: "Site Cassino Com Bônus",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/cassino-ao-vivo/bac-bo-online",
            img: null,
            title: "Melhores cassinos para jogar bac bo Online",
            content: "23 de maio de 2024",
            flg: 0,
        },

        {
            src: "/melhores-cassinos-online/pagam-rapido",
            img: "https://www.datocms-assets.com/102406/1716451088-cassinos-que-pagam-rapido.webp",
            title: "Cassinos que pagam rápido",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/bonus/gratis-50-reais",
            img: null,
            title: "Cassinos grátis de R$50 - Melhores Sites",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/melhores-cassinos-online/mais-pagam",
            img: null,
            title: "Cassinos que mais pagam",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/gameshows/crazy-coin-flip",
            img: "https://www.datocms-assets.com/102406/1693238592-crazy-coin-flip.jpg",
            title: "Crazy Coin Flip",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/gameshows/crazy-time",
            img: "https://www.datocms-assets.com/102406/1687873228-crazy-time.jpg",
            title: "Crazy Time",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots",
            img: null,
            title: "Caça Níqueis Slots",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/cripto-cassinos/ethereum",
            img: null,
            title: "Sites de Cassino que Aceitam Ethereum",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/metodos-de-pagamentos",
            img: null,
            title: "Métodos de pagamentos em cassinos",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/cripto-cassinos/tether",
            img: null,
            title: "Sites de cassino que Aceitam Tether USDT",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/provedores",
            img: null,
            title: "Provedores de cassino",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/cripto-cassinos/litecoin",
            img: null,
            title: "Sites de cassino que Aceitam LTC Litecoin",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/cripto-cassinos/ripple", //无页面  完成
            img: null,
            title: "Sites de cassino que Aceitam XRP Ripple",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/cripto-cassinos/bitcoin",
            img: null,
            title: "Sites de Cassino que Aceitam Bitcoin",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/politica-de-privacidade",
            img: null,
            title: "Política de Privacidade e Cookies",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/cassino-ao-vivo/roleta-espanhola-online", //  无页面 完成
            img: null,
            title: "Melhores cassinos com Roleta espanhola Online",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/cassino-ao-vivo/roleta-europeia-online",
            img: null,
            title: "Melhores cassinos com Roleta europeia Online",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/apostas/5-melhores-sites-de-aposta-com-criptomoedas", // 无页面 完成
            img: null,
            title: "5 Melhores sites de aposta com criptomoedas em 2024",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/cassino-ao-vivo/football-studio-online", //无页面  完成
            img: "https://www.datocms-assets.com/102406/1713787006-logo-jogo-foottball-studio.webp",
            title: "Melhores cassinos para jogar Football Studio Online",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/gameshows",
            img: null,
            title: "Game Shows",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/provedores/pg-soft",
            img: null,
            title: "Melhores Jogos PG Soft Gaming",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/bonus/rodadas-gratis",
            img: null,
            title: "Melhores Cassinos com Rodadas Grátis no Cadastro",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/gameshows/monopoly-live",
            img: "https://www.datocms-assets.com/102406/1689954014-monopoly-live.jpg",
            title: "Monopoly Live",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/melhores-cassinos-online/pagam-na-hora", //无页面 完成
            img: null,
            title: "Melhores sites de cassino que pagam na hora",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/metodos-de-pagamentos/pix",
            img: "https://www.datocms-assets.com/102406/1700133520-cassinos_com_pix.jpg",
            title: "Melhores sites de cassino com Pix",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/jogo-responsavel",
            img: null,
            title: "Jogo Responsável",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/jackpots",
            img: null,
            title: "Jackpots",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/provedores/pragmatic-play",
            img: null,
            title: "Melhores Jogos Pragmatic Play",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/melhores-cassinos-online/sites-jogos-azar",
            img: null,
            title: "Melhores Sites de Jogos de Azar",
            content: "23 de maio de 2024",
            flg: 0,
        },

        {
            src: "/cassino-ao-vivo/power-blackjack-online",
            img: null,
            title: "Melhores cassinos para jogar Power Blackjack Online",
            content: "23 de maio de 2024",
            flg: 0,
        },
        {
            src: "/cassino-ao-vivo/lightning-dice-online",
            img: null,
            title: "Melhores cassinos para jogar Lightning dice Online",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/cassino-ao-vivo/lucky-6-roulette-online",
            img: null,
            title: "Melhores cassinos com Lucky 6 Roulette Online",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/cassino-ao-vivo/lucky-ball-roulette-live",
            img: null,
            title: "Melhores cassinos com Lucky Ball Roulette Live",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/cassino-ao-vivo/xxxtreme-lightning-roulette-online",
            img: null,
            title: "Melhores cassinos com XXXtreme Lightning Roulette Online",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/melhores-cassinos-online/com-licenca",
            img: null,
            title: "Melhores sites de cassino Com licença",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/melhores-cassinos-online/limites-altos",
            img: null,
            title: "Melhores sites de cassino Com limites altos",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/bonus/sem-deposito",
            img: null,
            title: "Melhores Sites de Cassino Com Bônus Sem Depósito",
            content: "23 de maio de 2024",
            flg: 0,
        },

        {
            src: "/melhores-cassinos-online/dinheiro-real",
            img: null,
            title: "Melhores Cassinos- Com Dinheiro Real",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/melhores-cassinos-online/app",
            img: null,
            title: "Melhores sites de cassino Com Aplicativo",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/melhores-cassinos-online/novos",
            img: null,
            title: "Novos Cassinos 2024",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/melhores-cassinos-online/novos-cassinos-pagando-no-cadastro", // 无页面  完成
            img: null,
            title: "Melhores novos cassinos pagando no cadastro",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/sobre-nos",
            img: null,
            title: "Sobre Nós",
            content: "27 de fevereiro de 2024",
            flg: 0,
        },

        {
            src: "/metodos-de-pagamentos/neteller", // 无页面  完成
            img: null,
            title: "Melhores sites de cassino Com neteller",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/apostas/sites-de-apostas-que-aceitam-neteller", // 无页面  完成 疑问 Astropay模块缺失
            img: null,
            title: "Melhores sites de apostas que aceitam Neteller",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/bonus/vip",
            img: null,
            title: "Melhores Cassinos Com Ofertas VIPs",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/melhores-cassinos-online",
            img: null,
            title: "Melhores Sites de Cassino Online de 2024",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/cassino-ao-vivo/roleta-americana-online",
            img: null,
            title: "Melhores cassinos com Roleta americana Online",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/cassino-ao-vivo/airwave-roulette-online", //无页面 已完成
            img: null,
            title: "Melhores cassinos com Airwave Roulette Online",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/cripto-cassinos",
            img: null,
            title: "Sites de Cassino que Aceitam Criptomoedas",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/cassino-ao-vivo/roleta-relampago",
            img: null,
            title: "Roleta Relâmpago",
            content: "24 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/bonus/cashback",
            img: null,
            title: "Sites de cassino com Cashback",
            content: "23 de maio de 2024",
            flg: 0,
        },

        {
            src: "/metodos-de-pagamentos/transferencia-bancaria",
            img: null,
            title: "Melhores sites de cassino aceita transferência bancária",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/melhores-cassinos-online/torneio", //无页面  疑问  Pegar bônus 按钮地址错误
            img: null,
            title: "Melhores sites de cassino com torneio",
            content: "1 de junho de 2024",
            flg: 0,
        },

        {
            src: "/cassino-ao-vivo/mega-wheel-online",
            img: null,
            title: "Melhores cassinos para jogar Mega Wheel Online",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
    ],
    Notícias: [
        {
            src: "/noticias/aviator-parimatch", // 无页面  完成
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1698335051-aviator-parimatch.jpg&w=128&q=75",
            title: "Aviator Parimatch",
            content: "24 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/aviator-betano",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1698410713-aviator-betano.jpg&w=128&q=75",
            title: "Aviator Betano",
            content: "2 de maio de 2024",
            flg: 0,
        },
        {
            src: "/noticias/como-jogar-baccarat",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1687390934-tipos-de-baccarat-online.jpg&w=128&q=75",
            title: "Aprenda Como Jogar Baccarat: Regras e Estratégias Básicas",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/roleta-americana-o-que-e",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1687430677-roleta-brasileira-online.jpg&w=128&q=75",
            title: "Roleta americana o que é e como funciona",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/diferencas-blackjack-online-e-poker",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1712184029-diferenca-blackjack-e-poker.jpg&w=128&q=75",
            title: "Entenda as Principais Diferenças entre Blackjack Online e Poker",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/blaze-app",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1698938525-blaze-app.jpg&w=128&q=75",
            title: "Blaze app",
            content: "2 de maio de 2024",
            flg: 0,
        },
        {
            src: "/noticias/handicap-asiatico-00",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1689088738-handicap-asiatico-0-0.jpg&w=128&q=75",
            title: "Handicap Asiático 0.0",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/jogos-de-azar",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1697647449-melhores-sites-jogos-de-azar.jpg&w=128&q=75",
            title: "Jogos de azar",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/betano-login",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1699022449-436346346.png&w=128&q=75",
            title: "Betano login",
            content: "2 de maio de 2024",
            flg: 0,
        },
        {
            src: "/noticias/betano-cassino",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1699440660-betano-casino-2.jpg&w=128&q=75",
            title: "Betano Cassino",
            content: "2 de maio de 2024",
            flg: 0,
        },
        {
            src: "/noticias/betano-bonus",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1699288685-34234234234234.png&w=128&q=75",
            title: "Betano bônus",
            content: "2 de maio de 2024",
            flg: 0,
        },

        {
            src: "/noticias/double-blaze",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1694111795-blaze-double.jpg&w=128&q=75",
            title: "Double Blaze: O que é, Como ganhar e Dicas",
            content: "2 de maio de 2024",
            flg: 0,
        },
        {
            src: "/noticias/crash-blaze",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1694021269-crash-blaze.jpg&w=128&q=75",
            title: "Crash Blaze: O que é, Como ganhar e Dicas",
            content: "2 de maio de 2024",
            flg: 0,
        },
        {
            src: "/noticias/slots-com-bonus-de-boas-vindas",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1706250998-slots-com-bonus-de-boas-vindas.jpg&w=128&q=75",
            title: "Slots com bônus de boas-vindas",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/como-funciona-torneio-poker-nos-cassinos",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1712358101-torneios-de-poker-online.jpg&w=128&q=75",
            title: "Como funciona um torneio de poker nos sites de cassino?",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/como-jogar-dragon-hatch-no-celular",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695760754-como-jogar-dragon-hatch-no-celular.jpg&w=128&q=75",
            title: "Como jogar dragon hatch no celular?",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/como-jogar-fortune-ox-no-celular",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695662062-como-jogar-fortune-ox-no-celular.jpg&w=128&q=75",
            title: "Como jogar fortune ox no celular?",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/como-jogar-fortune-mouse-no-celular",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695333550-como-jogar-fortune-mouse-pelo-celular.jpg&w=128&q=75",
            title: "Como jogar fortune mouse no celular?",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/como-jogar-fortune-rabbit-no-celular",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695315961-como-jogar-fortune-rabbit-pelo-celular.jpg&w=128&q=75",
            title: "Como jogar fortune rabbit no celular?",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/como-funciona-a-roleta",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1694516591-como-funciona-a-roleta.jpg&w=128&q=75",
            title: "Como funciona a Roleta?",
            content: "2 de maio de 2024",
            flg: 0,
        },

        {
            src: "/noticias/como-jogar-cassino",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1693308871-como-jogar-cassino.jpg&w=128&q=75",
            title: "Como jogar Cassino?",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/dicas-para-jogar-blackjack",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1706250376-dicas-para-jogar-blackjack.jpg&w=128&q=75",
            title: "Dicas para jogar blackjack",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/dragon-hatch-plataforma-nova",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1697798924-dragon-hatch-plataforma-nova.jpg&w=128&q=75",
            title: "Dragon Hatch Plataforma Nova",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/dragon-hatch-vale-a-pena",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1697121334-dragon-hatch-vale-a-pena.jpg&w=128&q=75",
            title: "Dragon hatch vale a pena",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/dragon-hatch-estrategias",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695763100-dragon-hatch-estrategias.jpg&w=128&q=75",
            title: "Dragon Hatch estratégias",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/dragon-hatch-como-sacar",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695762610-dragon-hatch-como-sacar.jpg&w=128&q=75",
            title: "Dragon Hatch como sacar",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/dragon-hatch-deposito-de-1-real",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695761753-dragon-hatch-deposito-1-real.jpg&w=128&q=75",
            title: "Dragon hatch depósito de 1 real",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/dragon-hatch-deposito-de-5-reais",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695761211-dragon-hatch-deposito-5-reais.jpg&w=128&q=75",
            title: "Dragon hatch depósito de 5 reais",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/dragon-hatch-deposito-de-5-reais",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695761211-dragon-hatch-deposito-5-reais.jpg&w=128&q=75",
            title: "Dragon hatch depósito de 5 reais",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/dragon-hatch-nao-consigo-sacar",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695736848-dragon-hatch-saque-nao-aprova.jpg&w=128&q=75",
            title: "Dragon hatch não consigo sacar",
            content: "31 de maio de 2024",
            flg: 0,
        },

        {
            src: "/noticias/melhor-estrategia-para-jogar-crazy-time",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1690035260-estrategias-crazy-time.jpg&w=128&q=75",
            title: "Melhor estratégia para jogar Crazy Time",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/o-que-e-um-dealer",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1707617977-partida-dealer-casino.jpg&w=128&q=75",
            title: "O que é um Dealer?",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/dragon-hatch-esta-pagando",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695727728-dragon-hatch-esta-pagando.jpg&w=128&q=75",
            title: "Dragon Hatch está pagando",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/dragon-hatch-esta-fora-do-ar",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695726950-dragon-hatch-erro-fora-do-ar.jpg&w=128&q=75",
            title: "Dragon Hatch Está Fora do Ar",
            content: "2 de maio de 2024",
            flg: 0,
        },
        {
            src: "/noticias/fortune-ox-estrategias",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695663735-estrategias-fortune-ox.jpg&w=128&q=75",
            title: "Fortune OX estratégias",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/fortune-ox-esta-pagando",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695658878-fortune-ox-esta-realmente-pagando.jpg&w=128&q=75",
            title: "Fortune OX está pagando",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/fortune-mouse-plataforma-nova",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1697797790-fortune-mouse-plataforma-nova.jpg&w=128&q=75",
            title: "Fortune Mouse Plataforma Nova",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/fortune-ox-plataforma-nova",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1697798397-fortune-ox-plataforma-nova.jpg&w=128&q=75",
            title: "Fortune OX Plataforma Nova",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/plataformas-2-reais-tigre",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1717497021-plataformas-tigre-2-reais.jpg&w=128&q=75",
            title: "Fortune tiger plataforma de 2 reais tigre",
            content: "4 de junho de 2024",
            flg: 0,
        },
        {
            src: "/noticias/fortune-rabbit-plataforma-nova",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1697796923-fortune-rabbit-plataforma-nova.jpg&w=128&q=75",
            title: "Fortune Rabbit Plataforma Nova",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/fortune-tiger-plataforma-nova",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1697796089-plataforma-nova-para-jogar-fortune-tiger.jpg&w=128&q=75",
            title: "Fortune Tiger Plataforma Nova",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/fortune-mouse-vale-a-pena",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1697120084-fortune-mouse-vale-a-pena.jpg&w=128&q=75",
            title: "Fortune mouse vale a pena",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/fortune-rabbit-vale-a-pena",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695924187-fortune-rabbit-e-confiavel.jpg&w=128&q=75",
            title: "Fortune rabbit vale a pena",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/fortune-tiger-vale-a-pena",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695902887-fortune-tiger-vale-a-pena.jpg&w=128&q=75",
            title: "Fortune tiger vale a pena",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/gale-no-cassino",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1688561457-o-que-e-gale-nos-cassinos.jpg&w=128&q=75",
            title: "Gale no Cassino",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/minutos-pagantes-piggy-gold",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1705406236-minutos-pagantes-piggy-gold.png&w=128&q=75",
            title: "Minutos Pagantes Piggy Gold",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/minutos-pagantes-santas-gift-rush",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1704706075-santas-gift-rush.webp&w=128&q=75",
            title: "Minutos Pagantes Santa's Gift Rush",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/melhor-horario-para-jogar-ganesha-fortune",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1697132650-melhores-horarios-para-jogar-ganesha-fortune.jpg&w=128&q=75",
            title: "Melhor Horário Para Jogar Ganesha Fortune",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/melhor-horario-para-jogar-ganesha-gold",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1697131592-melhores-horarios-para-jogar-ganesha-gold.jpg&w=128&q=75",
            title: "Melhor Horário Para Jogar Ganesha Gold",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/melhor-horario-para-jogar-gates-of-oylmpus",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1697122572-melhor-horario-para-jogar-gates-of-olympus.jpg&w=128&q=75",
            title: "Melhor Horário Para Jogar Gates of Oylmpus",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/melhor-horario-para-jogar-santas-gift-rush",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1704230091-melhores-horarios-para-jogar-santa-s-gift-rush.jpg&w=128&q=75",
            title: "Melhor Horário Para Jogar Santa's Gift Rush",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/melhor-horario-para-jogar-piggy-gold",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1704221125-melhor-horario-para-jogar-piggy-gold.jpg&w=128&q=75",
            title: "Melhor Horário Para Jogar Piggy Gold",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/handicap-negativo",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1689590464-handicap-negativo.jpg&w=128&q=75",
            title: "Handicap negativo",
            content: "21 de abril de 2024",
            flg: 0,
        },

        {
            src: "/noticias/handicap-menos1",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1689077165-handicap-1-apostas.jpg&w=128&q=75",
            title: "Handicap -1",
            content: "21 de abril de 2024",
            flg: 0,
        },
        {
            src: "/noticias/handicap2",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1689071058-handicap-2-apostas.jpg&w=128&q=75",
            title: "Handicap +2",
            content: "21 de abril de 2024",
            flg: 0,
        },

        {
            src: "/noticias/handicap3",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1688559145-handicap-3.jpg&w=128&q=75",
            title: "Handicap +3",
            content: "21 de abril de 2024",
            flg: 0,
        },
        {
            src: "/noticias/melhor-horario-para-jogar-songkran-splash",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1704223164-songkran-splash-melhor-horario.jpg&w=128&q=75",
            title: "Melhor Horário Para Jogar Songkran Splash",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/melhor-horario-para-jogar-fruit-party",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1704220125-fruit-party-melhor-horario.jpg&w=128&q=75",
            title: "Melhor Horário Para Jogar Fruit Party",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/melhor-horario-para-jogar-the-great-icescape",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1697125552-melhores-horarios-para-jogar-the-great-icescape.jpg&w=128&q=75",
            title: "Melhor Horário Para Jogar The Great Icescape",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/como-jogar-slots-caca-niqueis",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1691151656-como-jogar-caca-niqueis.jpg&w=128&q=75",
            title: "Como jogar Slots Caça-Níqueis?",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/como-jogar-fortune-tiger-no-celular",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1690060018-como-jogar-fortune-tiger-celular.jpg&w=128&q=75",
            title: "Como jogar fortune tiger no celular?",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/melhor-horario-para-jogar-lucky-piggy",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1705340325-lucky-piggy-melhor-horario.jpg&w=128&q=75",
            title: "Melhor Horário Para Jogar Lucky Piggy",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/melhor-horario-para-jogar-leprechaun-riches",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1697130735-melhores-horarios-para-jogar-leprechaun-riches.jpg&w=128&q=75",
            title: "Melhor Horário Para Jogar Leprechaun Riches",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/minutos-pagantes-buffalo-win",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1704229314-melhores-horarios-para-jogar-buffalo-win.jpeg&w=128&q=75",
            title: "Minutos Pagantes Buffalo Win",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/minutos-pagantes-hip-hop-panda",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1704323893-pgs_hiphoppanda-1.jpg&w=128&q=75",
            title: "Minutos Pagantes Hip Hop Panda",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/melhor-horario-para-jogar-wild-fireworks",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1704352316-melhores-horarios-para-jogar-wild-fireworks.jpg&w=128&q=75",
            title: "Melhor Horário Para Jogar Wild Fireworks",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/melhor-horario-para-jogar-wild-bandito",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1704350721-wild-bandito-melhor-horario.jpg&w=128&q=75",
            title: "Melhor Horário Para Jogar Wild Bandito",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/principais-termos-siglas-nos-cassinos",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1706808618-principais-termos-nos-cassinos-online.jpg&w=128&q=75",
            title: "Principais termos nos cassinos",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/fortune-ox-nao-consigo-sacar",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695661228-fortune-ox-nao-consigo-sacar.jpg&w=128&q=75",
            title: "Fortune ox não consigo sacar",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/fortune-mouse-nao-consigo-sacar",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695332804-fortune-mouse-nao-consigo-sacar.jpg&w=128&q=75",
            title: "Fortune Mouse nao consigo sacar",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/fortune-rabbit-nao-consigo-sacar",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695137715-fortune-rabbit-nao-consigo-fazer-saque.jpg&w=128&q=75",
            title: "Fortune rabbit não consigo sacar",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/fortune-tiger-nao-consigo-sacar",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1689765640-fortune-tiger-nao-consigo-sacar.jpg&w=128&q=75",
            title: "Fortune tiger não consigo sacar",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/even-na-roleta",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1694448235-o-que-significa-even-na-roleta.jpg&w=128&q=75",
            title: "O que significa even na Roleta?",
            content: "2 de maio de 2024",
            flg: 0,
        },
        {
            src: "/noticias/o-que-sao-jackpots",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1691150732-o-que-e-jackpot.jpg&w=128&q=75",
            title: "O que são Jackpots?",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/melhores-jogos-de-jackpot",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1716376689-big-win-jackpot.jpg&w=128&q=75",
            title: "Quais os Melhores Jogos de Jackpot para jogar?",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/fortune-ox-como-sacar",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695663242-fortune-ox-sacar-dinheiro.jpg&w=128&q=75",
            title: "Fortune OX como sacar",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/fortune-ox-deposito-de-1-real",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695662870-fortune-ox-deposito-de-1-real.jpg&w=128&q=75",
            title: "Fortune OX depósito de 1 real",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/minutos-pagantes-bikini-paradise",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1704221807-melhores-horarios-para-jogar-bikini-paradise.jpg&w=128&q=75",
            title: "Minutos Pagantes Bikini Paradise",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/minutos-pagantes-songkran-splash",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1704309029-songkran-splash.webp&w=128&q=75",
            title: "Minutos pagantes Songkran Splash",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/quanto-paga-cada-numero-da-roleta",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1694439685-quanto-paga-cada-numero-na-roleta.jpg&w=128&q=75",
            title: "Quanto paga cada número Roleta?",
            content: "2 de maio de 2024",
            flg: 0,
        },
        {
            src: "/noticias/blaze-jogos",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1693932532-jogos-originais-blaze.jpg&w=128&q=75",
            title: "Quais os jogos mais famosos da Blaze?",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/quanto-paga-0-roleta",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1693308332-quanto-paga-o-0-na-roleta.jpg&w=128&q=75",
            title: "Quanto paga o 0 na Roleta?",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/quem-da-as-cartas-no-cassino",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1691157056-quem-da-as-cartas-no-cassino.jpg&w=128&q=75",
            title: "Quem dá as cartas no cassino?",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/fortune-rabbit-estrategias",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695318701-estrategias-fortune-rabbit.jpg&w=128&q=75",
            title: "Fortune rabbit estratégias",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/fortune-rabbit-como-sacar",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695317725-sacar-dinheiro-fortune-rabbit.jpg&w=128&q=75",
            title: "Fortune Rabbit como sacar",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/fortune-rabbit-deposito-de-1-real",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695317186-fortune-rabbit-deposito-1-real.jpg&w=128&q=75",
            title: "Fortune rabbit depósito de 1 real",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/fortune-rabbit-deposito-de-5-reais",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695316633-fortune-rabbit-deposito-5-reais.jpg&w=128&q=75",
            title: "Fortune rabbit depósito de 5 reais",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        ,
        {
            src: "/noticias/como-sacar-o-dinheiro-do-jogo-aviator",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1689934335-como-sacar-o-dinheiro-do-aviator.jpg&w=128&q=75",
            title: "Como sacar o dinheiro do jogo Aviator",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/tipos-de-roleta-de-cassino",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1694516591-como-funciona-a-roleta.jpg&w=128&q=75",
            title: "Tipos de roleta de cassino",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/fortune-tiger-deposito-de-1-real",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1695314177-fortune-tiger-deposito-de-1-real.jpg&w=128&q=75",
            title: "Fortune tiger depósito de 1 real",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/sacar-dinheiro-fortune-tiger",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1690065653-fortune-tiger-como-sacar.jpg&w=128&q=75",
            title: "Fortune Tiger como sacar",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/fortune-tiger-deposito-de-5-reais",
            img: "https://casinos24.com.br/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1690062718-fortune-tiger-deposito-5-reais.jpg&w=128&q=75",
            title: "Fortune tiger: Plataforma de 5 reais tigre",
            content: "4 de junho de 2024",
            flg: 0,
        },

        {
            src: "/noticias/como-pegar-vela-rosa-no-aviator",
            img: "https://www.datocms-assets.com/102406/1689266759-como-pegar-vela-rosa-no-aviator.jpg",
            title: "Como pegar vela rosa no aviator",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/dragon-hatch-quanto-vale-cada-figura",
            img: "https://www.datocms-assets.com/102406/1695689032-simbolos-figura-dragon-hatch.jpg",
            title: "Dragon Hatch quanto vale cada figura",
            content: "2 de maio de 2024",
            flg: 0,
        },
        {
            src: "/noticias/fortune-ox-quanto-vale-cada-figura",
            img: "https://www.datocms-assets.com/102406/1695657796-fortune-ox-simbolos-e-pagamentos.jpg",
            title: "Fortune OX quanto vale cada figura",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/fortune-mouse-quanto-vale-cada-figura",
            img: "https://www.datocms-assets.com/102406/1695322802-quanto-vale-cada-simbolo-no-fortune-mouse.jpg",
            title: "Fortune Mouse quanto vale cada figura",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/minutos-pagantes-wild-bandito",
            img: "https://www.datocms-assets.com/102406/1704351623-minutos-pagantes-wild-bandito.jpeg",
            title: "Minutos Pagantes Wild Bandito",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/noticias/melhor-horario-para-jogar-buffalo-win",
            img: "https://www.datocms-assets.com/102406/1704229314-melhores-horarios-para-jogar-buffalo-win.jpeg",
            title: "Melhor Horário Para Jogar Buffalo Win",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/noticias/melhor-horario-para-jogar-caishen-wins",
            img: "https://www.datocms-assets.com/102406/1697133376-melhores-horarios-para-jogar-caishen-wins.jpg",
            title: "Melhor Horário Para Jogar Caishen Wins",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
    ],
    Slots: [
        {
            src: "/slots/action-boost-gladiator",
            img: "https://www.datocms-assets.com/102406/1705571218-logo-slot-action-boost-gladiator.png",
            title: "Action Boost Gladiator",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/spin-and-win",
            img: "https://www.datocms-assets.com/102406/1706843916-spind-and-win.png",
            title: "Spin and Win",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/wisdom-of-athena",
            img: "https://www.datocms-assets.com/102406/1715678514-jogo-de-cassino-wisdom-of-athena.webp",
            title: "Wisdom of Athena",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/hand-of-anubis",
            img: "https://www.datocms-assets.com/102406/1706089744-logo-slot-hand-of-anubis.jpg",
            title: "Hand of Anubis",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/wanted-dead-or-a-wild",
            img: "https://www.datocms-assets.com/102406/1706175497-wanted-dead-or-a-wild-logo.webp",
            title: "Wanted Dead or a Wild",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/rotten",
            img: "https://www.datocms-assets.com/102406/1693852662-rotten.jpg",
            title: "Rotten",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/sweet-bonanza",
            img: "https://www.datocms-assets.com/102406/1698075656-sweet-bonanza-slot.jpg",
            title: "Sweet Bonanza",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/bikini-paradise",
            img: "https://www.datocms-assets.com/102406/1704353962-bikini-paradise-slot.webp",
            title: "Bikini Paradise",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/book-of-the-fallen",
            img: "https://www.datocms-assets.com/102406/1706840597-book-of-the-fallen-slot.jpg",
            title: "Book of the Fallen",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/buffalo-win",
            img: "https://www.datocms-assets.com/102406/1704715793-buffalo-win.webp",
            title: "Buffalo Win",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/big-bass-bonanza",
            img: "https://www.datocms-assets.com/102406/1704988443-big-bass-bonanza-slot.webp",
            title: "Big Bass Bonanza",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/wild-bandito",
            img: "https://www.datocms-assets.com/102406/1705914322-slot-wild-bandito.jpg",
            title: "Wild Bandito",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/candy-bonanza",
            img: "https://www.datocms-assets.com/102406/1707902827-logo-candy-bonanza.png",
            title: "Candy Bonanza",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/chaos-crew",
            img: "https://www.datocms-assets.com/102406/1706002781-jogo-chaos-crew.png",
            title: "Chaos Crew",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/caishen-wins",
            img: "https://www.datocms-assets.com/102406/1697029415-caishen-wins-cassino.jpg",
            title: "Caishen Wins",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/20-coins",
            img: "https://www.datocms-assets.com/102406/1705339494-logo-slot-20-coins.webp",
            title: "20 Coins",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/rip-city",
            img: "https://www.datocms-assets.com/102406/1706615732-logo-slot-rip-city.png",
            title: "RIP City",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/dragon-hatch-2",
            img: "https://www.datocms-assets.com/102406/1706778435-logo-dragon-hatch-2.jpg",
            title: "Dragon Hatch 2",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/double-fortune",
            img: "https://www.datocms-assets.com/102406/1696249596-double-fortune-cassino.jpg",
            title: "Double Fortune",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/dragon-hero",
            img: "https://www.datocms-assets.com/102406/1691734000-dragon-hero.webp",
            title: "Dragon Hero",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/dragon-hatch",
            img: "https://www.datocms-assets.com/102406/1690387502-dragon-hatch-analise.jpg",
            title: "Dragon Hatch",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/fortune-dragon",
            img: "https://www.datocms-assets.com/102406/1706253136-fortune-dragon-slot.jpg",
            title: "Fortune Dragon",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/fruit-party",
            img: "https://www.datocms-assets.com/102406/1703763496-maxresdefault-2.jpg",
            title: "Fruit Party",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/fortune-gods",
            img: "https://www.datocms-assets.com/102406/1691004394-fortune-gods-cassino.jpg",
            title: "Fortune Gods",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/fortune-mouse",
            img: "https://www.datocms-assets.com/102406/1690974419-fortune-mouse.webp",
            title: "Fortune Mouse",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/fortune-rabbit",
            img: "https://www.datocms-assets.com/102406/1689246532-fortune-rabbit-slot.webp",
            title: "Fortune Rabbit",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/fortune-ox",
            img: "https://www.datocms-assets.com/102406/1689145098-fortune-ox-slot.webp",
            title: "Fortune OX",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/fortune-tiger",
            img: "https://www.datocms-assets.com/102406/1687342737-fortune-tiger-slot.webp",
            title: "Fortune Tiger",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/wild-fireworks",
            img: "https://www.datocms-assets.com/102406/1705615375-wild-fireworks-slot-de-video-pg-soft.webp",
            title: "Wild Fireworks",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/gonzos-quest",
            img: "https://www.datocms-assets.com/102406/1706847490-gonzo-s-quest.jpg",
            title: "Gonzo's Quest",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/ganesha-fortune",
            img: "https://www.datocms-assets.com/102406/1696955189-ganesha-fortune-cassino.jpeg",
            title: "Ganesha Fortune",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/ganesha-gold",
            img: "https://www.datocms-assets.com/102406/1696882102-ganesha-gold-cassino.jpg",
            title: "Ganesha Gold",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/gates-of-olympus",
            img: "https://www.datocms-assets.com/102406/1688667359-gates-of-olympus-cassino.jpg",
            title: "Gates of Olympus",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/piggy-gold",
            img: "https://www.datocms-assets.com/102406/1703843095-b92cbcd4-bdde-4729-86b8-775b386a707c.jpg",
            title: "Piggy Gold",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/santas-gift-rush",
            img: "https://www.datocms-assets.com/102406/1704706075-santas-gift-rush.webp",
            title: "Santa's Gift Rush",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/the-great-icescape",
            img: "https://www.datocms-assets.com/102406/1691071298-the-great-icescape-cassino.jpeg",
            title: "The Great Icescape",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/hip-hop-panda",
            img: "https://www.datocms-assets.com/102406/1704450487-hiphop-panda.webp",
            title: "Hip Hop Panda",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/hot-pepper",
            img: "https://www.datocms-assets.com/102406/1713281752-logo-slot-hot-pepper.webp",
            title: "Hot Pepper",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/werewolf-s-hunt",
            img: "https://www.datocms-assets.com/102406/1707123418-logo-werewolf-s-hunt-slot.jpg",
            title: "Werewolf's Hunt",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/zeus-vs-hades",
            img: "https://www.datocms-assets.com/102406/1706259046-logo-zeus-vs-hades-gods-of-war.jpg",
            title: "Zeus vs Hades",
            content: "1 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/infective-wild",
            img: "https://www.datocms-assets.com/102406/1703752702-infective-wild-slot.webp",
            title: "Infective Wild",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/queen-of-ice",
            img: "https://www.datocms-assets.com/102406/1711972844-slot-queen-of-ice.jpg",
            title: "Queen of Ice",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/jewels-of-prosperity",
            img: "https://www.datocms-assets.com/102406/1714039225-caca-niquel-jewels-of-prosperity.jpg",
            title: "Jewels of Prosperity",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/monster-superlanche",
            img: "https://www.datocms-assets.com/102406/1699628454-monstersuperlanche_1000x666.jpg",
            title: "Monster Superlanche",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/lucky-piggy",
            img: "https://www.datocms-assets.com/102406/1705310314-logo-lucky-piggy.jpg",
            title: "Lucky Piggy",
            content: "30 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/leprechaun-riches",
            img: "https://www.datocms-assets.com/102406/1696630711-leprechaun-riches-slot.jpg",
            title: "Leprechaun Riches",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/money-train-2",
            img: "https://www.datocms-assets.com/102406/1707620806-money-train-2.jpeg",
            title: "Money Train 2",
            content: "1 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/mystical-spirits",
            img: "https://www.datocms-assets.com/102406/1714381195-slot-mystical-spirits-da-pg-soft.png",
            title: "Mystical Spirits",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/madame-fortune",
            img: "https://www.datocms-assets.com/102406/1691586473-madame_fortune.webp",
            title: "Madame Fortune",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/midas-fortune",
            img: "https://www.datocms-assets.com/102406/1690789387-midas-fortune-pgsoft-games.webp",
            title: "Midas Fortune",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/rasputin-megaways",
            img: "https://www.datocms-assets.com/102406/1690882075-rasputin-megaways.webp",
            title: "Rasputin Megaways",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
        {
            src: "/slots/piggy-bank-megaways",
            img: "https://www.datocms-assets.com/102406/1690788758-piggy-bank-megaways-1.webp",
            title: "Piggy Bank Megaways",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/tree-of-fortune",
            img: "https://www.datocms-assets.com/102406/1691735277-tree_of_fortune.webp",
            title: "Tree of Fortune",
            content: "30 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/piggy-riches",
            img: "https://www.datocms-assets.com/102406/1691433314-piggy-riches.png",
            title: "Piggy Riches",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/songkran-splash",
            img: "https://www.datocms-assets.com/102406/1704309029-songkran-splash.webp",
            title: "Songkran Splash",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/tsar-treasures",
            img: "https://www.datocms-assets.com/102406/1704878474-6be0f854-3817-44c5-983b-809833878ced.jpg",
            title: "Tsar Treasures",
            content: "29 DE maio DE 2024",
            flg: 0,
        },

        {
            src: "/slots/wild-wild-bananas",
            img: "https://www.datocms-assets.com/102406/1712305562-slot-cassino-wild-wild-bananas.webp",
            title: "Wild Wild Bananas",
            content: "29 DE maio DE 2024",
            flg: 0,
        },
    ],
};
