export default {
    install(Vue) {
        Vue.prototype.$goFullscreen = function () {
            var iframe = document.querySelector("iframe"); // 使用querySelector选择第一个iframe
            if (iframe.requestFullscreen) {
                iframe.requestFullscreen(); // 标准方法
            } else if (iframe.mozRequestFullScreen) {
                /* Firefox */
                iframe.mozRequestFullScreen();
            } else if (iframe.webkitRequestFullscreen) {
                /* Chrome, Safari 和 Opera */
                iframe.webkitRequestFullscreen();
            } else if (iframe.msRequestFullscreen) {
                /* IE/Edge */
                iframe.msRequestFullscreen();
            }
        };
    },
};
