import Vue from "vue";
import FTWComponents from '@/components/FTWBtn.vue'
//页面置顶
Vue.prototype.$scrollToTop = function (el) {
    runToTop()
};
//reviews tab切换
Vue.prototype.$changeTab = function (e) {
    if (!e.target || !e.currentTarget) return;
    let child = e.currentTarget.children
    for (let i = 0; i < child.length; i++) {
        //处理tab 颜色
        if (e.target.id === child[i].children[0].id) {
            child[i].children[0].className = 'text-xs font-bold cursor-pointer uppercase px-5 py-3 block leading-normal text-blue-900 bg-blue-100 border border-blue-100'
        } else {
            child[i].children[0].className = 'text-xs font-bold cursor-pointer uppercase px-5 py-3 block leading-normal border border-slate-200'
        }
    }
    let el = document.querySelectorAll('[aria-labelledby]');
    let id = e.target.id.charAt(4, 1);
    if (!id || el.length !== 3) return
    for (let i = 0; i < el.length; i++) {
        //处理块显示
        if (id - 1 === i) {
            el[i].className = 'block'
        } else {
            el[i].className = 'hidden'
        }
    }
};
//reviews 手风琴切换
Vue.prototype.$changeDescription = function (e) {
    let target = e.target, svg = e.target.querySelectorAll('svg')[0]
    if (e.target.localName === 'h3') target = target.parentElement //点击到title时 需要向上找一层
    let id = target.getAttribute('aria-controls')
    let el = document.getElementById(id)
    if (!el || !el.classList) return;
    let description = document.getElementsByClassName('show-description')
    let h3 = document.querySelectorAll('h3')
    for (let a = 0; a < h3.length; a++) {
        //将所有的箭头设置旋转为0
        if (h3[a].childNodes.length >= 2) {
            h3[a].childNodes[1].style.transform = 'rotate(0)'
        }
    }
    let hasClass = el.classList.contains('show-description') //获取当前元素是否是已经有高亮class
    for (let i = 0; i < description.length; i++) {
        //清除所有含有的class
        description[i].className = 'faq__desc'
        svg.style.transform = 'rotate(0)'
    }
    if (hasClass) return; //如果有 则不执行后面的函数
    el.className = 'faq__desc show-description'
    svg.style.transform = 'rotate(180deg)'
};
//iframe
Vue.prototype.$openIframe = function (type) {
    let str = ''
    switch (type) {
        case 'facebook':
            str = 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcasinos24.com.br%2Fbonus'
            break

        case 'twitter':
            str = 'https://twitter.com/share?url=https%3A%2F%2Fcasinos24.com.br%2Fbonus&text=12%20Melhores%20Sites%20de%20Cassino%20Com%20B%C3%B4nus%20de%20Boas-Vindas'
            break

        case 'whatsapp':
            str = 'https://web.whatsapp.com/send?text=12%20Melhores%20Sites%20de%20Cassino%20Com%20B%C3%B4nus%20de%20Boas-Vindas%20https%3A%2F%2Fcasinos24.com.br%2Fbonus'
            break

    }

    window.open(str, '_blank', 'width=550px,height=400px,top=300px,left=500px')

}
//注册全局组件
Vue.component('FTWComponents', FTWComponents);

function runToTop() {
    let currentPosition, timer;
    const speed = 20;
    timer = setInterval(function () {
        currentPosition = document.documentElement.scrollTop || document.body.scrollTop;
        currentPosition -= speed; //speed变量
        if (currentPosition > 0) {
            window.scrollTo(0, currentPosition);
        } else {
            window.scrollTo(0, 0);
            clearInterval(timer);
        }
    }, 1);
}
