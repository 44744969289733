<template>
  <div
      class="fixed bottom-8 right-8 z-40 bg-yellow-400 h-9 w-9 flex rounded-full items-center justify-center"
  >
    <button class="btn-scrollTop" @click="$scrollToTop">
      <svg
          class="h-5 w-5"
          fill="none"
          height="24"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="12" x2="12" y1="19" y2="5"></line>
        <polyline points="5 12 12 5 19 12"></polyline>
      </svg>
    </button>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>