<script>
export default {
  name: "LeftDrawer",
  data() {
    return {};
  },

  mounted() {
    document.body.style.overflow = "hidden";
  },
  destroyed() {
    document.body.style.overflow = "";
  },
  methods: {
    open(e) {
      if (e.target.localName !== "svg") return;
      let button = e.target.parentElement;
      let ul = e.target.parentElement.nextElementSibling;
      if (button.classList.contains("rotate-180")) {
        button.className =
            "ml-auto ml-auto w-7 transition-transform duration-200 ease-in-out";
      } else {
        button.className =
            "rotate-180 ml-auto w-7 transition-transform duration-200 ease-in-out";
      }
      //
      if (ul.classList.contains("hidden")) {
        ul.className = "block my-2 basis-full pl-10";
      } else {
        ul.className = "hidden my-2 basis-full pl-10";
      }
    },
    close() {
      this.$emit("openLeftDrawer");
    },
  },
};
</script>

<template>
  <div>
    <div
        class="fixed inset-0 z-40 overflow-hidden bg-slate-900/50 transition-opacity duration-300 ease-in-out backdrop-blur-[2px] lg:block opacity-75 }"
        @click="close()"
    ></div>
    <nav
        aria-label="Sidebar menu"
        class="fixed bottom-0 right-0 top-0 z-50 w-[calc(100%-40px)] overflow-y-auto bg-slate-900 px-4 py-8 duration-300 ease-in-out lg:max-w-md translate-x-0"
    >
      <button
          aria-label="close menu"
          class="absolute right-4 top-4 text-white lg:right-6 lg:top-6"
          @click="close()"
      >
        <svg
            aria-label="hidden"
            class="h-7 w-7 text-white"
            fill="none"
            height="24"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
          <line x1="18" x2="6" y1="6" y2="18"></line>
          <line x1="6" x2="18" y1="6" y2="18"></line>
        </svg>
      </button>
      <ul class="mt-14 px-2 text-white" role="menubar">
        <li
            class="flex flex-wrap items-center justify-between border-b border-b-slate-800 py-3"
            role="none"
        >
          <a
              class="flex max-w-[calc(100%-24px)] gap-x-3 overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-semibold text-white hover:text-slate-400"
              href="/melhores-cassinos-online"
              role="menuitem"
          ><img
              alt="Cassino online"
              class="invert"
              data-nimg="1"
              decoding="async"
              height="26"
              loading="lazy"
              src="https://www.datocms-assets.com/102406/1706800657-cassino-online.svg"
              style="color: transparent"
              width="28"
          />Cassino Online</a
          >
          <button
              aria-expanded="false"
              aria-haspopup="true"
              aria-label="Öppna undermeny"
              class="ml-auto w-7 transition-transform duration-200 ease-in-out"
              role="menuitem"
              @click="open"
          >
            <svg
                aria-label="hidden"
                class="w-full stroke-white"
                fill="none"
                height="24"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>
          <ul class="hidden my-2 basis-full pl-10" role="menu">
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/melhores-cassinos-online/sites-jogos-azar"
                  role="menuitem"
              >Sites de jogos de azar</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/melhores-cassinos-online/novos"
                  role="menuitem"
              >Cassinos Novos</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/melhores-cassinos-online/pagam-rapido"
                  role="menuitem"
              >Cassinos que pagam Rápido</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/melhores-cassinos-online/mais-pagam"
                  role="menuitem"
              >Cassinos que mais Pagam</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/melhores-cassinos-online/com-licenca"
                  role="menuitem"
              >Cassinos com Licença</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/melhores-cassinos-online/limites-altos"
                  role="menuitem"
              >Cassinos com Limites Altos</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/melhores-cassinos-online/app"
                  role="menuitem"
              >Cassinos com App</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/cripto-cassinos"
                  role="menuitem"
              >Cassinos Aceitam Criptomoedas</a
              >
            </li>
          </ul>
        </li>
        <li
            class="flex flex-wrap items-center justify-between border-b border-b-slate-800 py-3"
            role="none"
        >
          <a
              class="flex max-w-[calc(100%-24px)] gap-x-3 overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-semibold text-white hover:text-slate-400"
              href="/bonus"
              role="menuitem"
          ><img
              alt="Bonus de cassino"
              class="invert"
              data-nimg="1"
              decoding="async"
              height="26"
              loading="lazy"
              src="https://www.datocms-assets.com/102406/1706800689-bonus-de-cassino.svg"
              style="color: transparent"
              width="28"
          />Bônus</a
          >
          <button
              aria-expanded="false"
              aria-haspopup="true"
              aria-label="Öppna undermeny"
              class="ml-auto w-7 transition-transform duration-200 ease-in-out"
              role="menuitem"
              @click="open"
          >
            <svg
                aria-label="hidden"
                class="w-full stroke-white"
                fill="none"
                height="24"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>
          <ul class="hidden my-2 basis-full pl-10" role="menu">
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/bonus/vip"
                  role="menuitem"
              >VIP cassinos</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/bonus/rodadas-gratis"
                  role="menuitem"
              >Rodadas grátis</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/bonus/cadastro"
                  role="menuitem"
              >Bônus de cadastro</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/bonus/gratis-50-reais"
                  role="menuitem"
              >Bônus grátis R$50</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/bonus/sem-rollover"
                  role="menuitem"
              >Bônus sem rollover</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/bonus/sem-deposito"
                  role="menuitem"
              >Bônus sem depósito</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/bonus/cashback"
                  role="menuitem"
              >Cassinos com cashback</a
              >
            </li>
          </ul>
        </li>
        <li
            class="flex flex-wrap items-center justify-between border-b border-b-slate-800 py-3"
            role="none"
        >
          <a
              class="flex max-w-[calc(100%-24px)] gap-x-3 overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-semibold text-white hover:text-slate-400"
              href="/cassino-ao-vivo"
              role="menuitem"
          ><img
              alt="Cassino ao vivo"
              class="invert"
              data-nimg="1"
              decoding="async"
              height="26"
              loading="lazy"
              src="https://www.datocms-assets.com/102406/1706800730-cassino-ao-vivo.svg"
              style="color: transparent"
              width="28"
          />Cassino Ao Vivo</a
          >
          <button
              aria-expanded="false"
              aria-haspopup="true"
              aria-label="Öppna undermeny"
              class="ml-auto w-7 transition-transform duration-200 ease-in-out"
              role="menuitem"
              @click="open"
          >
            <svg
                aria-label="hidden"
                class="w-full stroke-white"
                fill="none"
                height="24"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>
          <ul class="hidden my-2 basis-full pl-10" role="menu">
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/cassino-ao-vivo/blackjack-online"
                  role="menuitem"
              >Blackjack online</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/cassino-ao-vivo/baccarat-online"
                  role="menuitem"
              >Baccarat online</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/cassino-ao-vivo/roleta-brasileira"
                  role="menuitem"
              >Roleta brasileira</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/cassino-ao-vivo/roleta-relampago"
                  role="menuitem"
              >Roleta relâmpago</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/cassino-ao-vivo/mega-fire-blaze-roulette"
                  role="menuitem"
              >Mega fire blaze roulette</a
              >
            </li>
          </ul>
        </li>
        <li
            class="flex flex-wrap items-center justify-between border-b border-b-slate-800 py-3"
            role="none"
        >
          <a
              class="flex max-w-[calc(100%-24px)] gap-x-3 overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-semibold text-white hover:text-slate-400"
              href="/gameshows"
              role="menuitem"
          ><img
              alt="Game shows"
              class="invert"
              data-nimg="1"
              decoding="async"
              height="26"
              loading="lazy"
              src="https://www.datocms-assets.com/102406/1706800792-gameshows.svg"
              style="color: transparent"
              width="28"
          />Game Shows</a
          >
          <button
              aria-expanded="false"
              aria-haspopup="true"
              aria-label="Öppna undermeny"
              class="ml-auto w-7 transition-transform duration-200 ease-in-out"
              role="menuitem"
              @click="open"
          >
            <svg
                aria-label="hidden"
                class="w-full stroke-white"
                fill="none"
                height="24"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>
          <ul class="hidden my-2 basis-full pl-10" role="menu">
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/gameshows/crazy-time"
                  role="menuitem"
              >Crazy time</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/gameshows/crazy-coin-flip"
                  role="menuitem"
              >Crazy coin flip</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/gameshows/monopoly-live"
                  role="menuitem"
              >Monopoly live</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/gameshows/live-mega-ball"
                  role="menuitem"
              >Live mega ball</a
              >
            </li>
          </ul>
        </li>
        <li
            class="flex flex-wrap items-center justify-between border-b border-b-slate-800 py-3"
            role="none"
        >
          <a
              class="flex max-w-[calc(100%-24px)] gap-x-3 overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-semibold text-white hover:text-slate-400"
              href="/slots"
              role="menuitem"
          ><img
              alt="Slots"
              class="invert"
              data-nimg="1"
              decoding="async"
              height="26"
              loading="lazy"
              src="../assets/images/leftDrawer/1692454885-slots.webp"
              srcset="../assets/images/leftDrawer/1692454885-slots.webp 2x"
              style="color: transparent"
              width="28"
          />Jogos de Caça-níqueis</a
          >
          <button
              aria-expanded="false"
              aria-haspopup="true"
              aria-label="Öppna undermeny"
              class="ml-auto w-7 transition-transform duration-200 ease-in-out"
              role="menuitem"
              @click="open"
          >
            <svg
                aria-label="hidden"
                class="w-full stroke-white"
                fill="none"
                height="24"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>
          <ul class="hidden my-2 basis-full pl-10" role="menu">
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/jackpots"
                  role="menuitem"
              >Jackpots</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/megaways"
                  role="menuitem"
              >Megaways</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/slots-com-alto-rtp"
                  role="menuitem"
              >Slots com alto RTP</a
              >
            </li>
          </ul>
        </li>
        <li
            class="flex flex-wrap items-center justify-between border-b border-b-slate-800 py-3"
            role="none"
        >
          <a
              class="flex max-w-[calc(100%-24px)] gap-x-3 overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-semibold text-white hover:text-slate-400"
              href="/crash"
              role="menuitem"
          ><img
              alt="Crash games aposta"
              class="invert"
              data-nimg="1"
              decoding="async"
              height="26"
              loading="lazy"
              src="https://www.datocms-assets.com/102406/1706800770-crash-games.svg"
              style="color: transparent"
              width="28"
          />Crash games</a
          >
        </li>
        <li
            class="flex flex-wrap items-center justify-between border-b border-b-slate-800 py-3"
            role="none"
        >
          <a
              class="flex max-w-[calc(100%-24px)] gap-x-3 overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-semibold text-white hover:text-slate-400"
              href="/apostas"
              role="menuitem"
          ><img
              alt="Sites de aposta"
              class="invert"
              data-nimg="1"
              decoding="async"
              height="26"
              loading="lazy"
              src="https://www.datocms-assets.com/102406/1706800832-sites-de-aposta.svg"
              style="color: transparent"
              width="28"
          />Sites de apostas</a
          >
          <button
              aria-expanded="false"
              aria-haspopup="true"
              aria-label="Öppna undermeny"
              class="ml-auto w-7 transition-transform duration-200 ease-in-out"
              role="menuitem"
              @click="open"
          >
            <svg
                aria-label="hidden"
                class="w-full stroke-white"
                fill="none"
                height="24"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>
          <ul class="hidden my-2 basis-full pl-10" role="menu">
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/apostas/bonus"
                  role="menuitem"
              >Casas de apostas com bônus</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/apostas/sites-de-apostas-que-aceitam-pix"
                  role="menuitem"
              >Sites de apostas que aceitam PIX</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/apostas/sites-de-apostas-que-aceitam-boleto"
                  role="menuitem"
              >Sites de apostas que aceitam boleto bancário</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/apostas/sites-de-apostas-que-aceitam-transferencia-bancaria"
                  role="menuitem"
              >Sites de apostas que aceitam transferência bancária</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/apostas/sites-de-apostas-que-aceitam-cartao-de-credito"
                  role="menuitem"
              >Sites de apostas que aceitam cartão de crédito</a
              >
            </li>
          </ul>
        </li>
        <li
            class="flex flex-wrap items-center justify-between border-b border-b-slate-800 py-3"
            role="none"
        >
          <a
              class="flex max-w-[calc(100%-24px)] gap-x-3 overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-semibold text-white hover:text-slate-400"
              href="/metodos-de-pagamentos"
              role="menuitem"
          ><img
              alt="Métodos de pagamentos em cassinos"
              class="invert"
              data-nimg="1"
              decoding="async"
              height="26"
              loading="lazy"
              src="https://www.datocms-assets.com/102406/1706800242-atm.svg"
              style="color: transparent"
              width="28"
          />Métodos de pagamentos</a
          >
          <button
              aria-expanded="false"
              aria-haspopup="true"
              aria-label="Öppna undermeny"
              class="ml-auto w-7 transition-transform duration-200 ease-in-out"
              role="menuitem"
              @click="open"
          >
            <svg
                aria-label="hidden"
                class="w-full stroke-white"
                fill="none"
                height="24"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>
          <ul class="hidden my-2 basis-full pl-10" role="menu">
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/metodos-de-pagamentos/pix"
                  role="menuitem"
              >Sites de cassino que aceitam PIX</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/metodos-de-pagamentos/boleto-bancario"
                  role="menuitem"
              >Sites de cassino que aceitam boleto bancário</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/metodos-de-pagamentos/transferencia-bancaria"
                  role="menuitem"
              >Sites de cassino que aceitam transferência bancária</a
              >
            </li>
            <li role="none">
              <a
                  class="mt-3 block text-slate-200 lg:hover:text-primary"
                  href="/metodos-de-pagamentos/cartao-credito"
                  role="menuitem"
              >Sites de cassino que aceitam cartão de crédito</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style scoped>
img {
  filter: invert(100%) !important;
}
</style>
