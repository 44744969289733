<!--<script>-->
<!--export default {-->
<!--  name: "SearchModal",-->
<!--  mounted() {-->
<!--    document.body.style.overflow = "hidden";-->
<!--  },-->
<!--  destroyed() {-->
<!--    document.body.style.overflow = "";-->
<!--  },-->
<!--  methods: {-->
<!--    close() {-->
<!--      this.$emit("closeSearchModal");-->
<!--    },-->
<!--    handleClick() {},-->
<!--  },-->
<!--};-->
<!--</script>-->

<template>
  <div>
    <div
        class="fixed inset-0 z-40 overflow-hidden bg-slate-900/50 transition-opacity duration-300 ease-in-out backdrop-blur-[2px] lg:block opacity-75 }"
    ></div>
    <div
        class="translate-y-0 translate-x-0 opacity-100 items-start mt-24 fixed inset-0 z-50 overflow-y-auto flex transition duration-300 justify-center p-3 pb-20 lg:pb-3 top-16"
        @click.self="close"
    >
      <div class="max-w-3xl w-full rounded-lg p-5 h-full sm:h-auto">
        <div class="h-auto">
          <div class="algolia-search relative w-full">
            <div
                class="pointer-events-none absolute flex h-full w-12 items-center justify-center overflow-hidden rounded-bl-md rounded-tl-md bg-primary"
            >
              <svg
                  aria-hidden="true"
                  class="h-4 w-4 text-black"
                  fill="none"
                  height="24"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" x2="16.65" y1="21" y2="16.65"></line>
              </svg>
            </div>
            <input
                id="search-algolia"
                v-model="queryStr"
                class="block w-full rounded-md bg-white py-4 pl-16 pr-3 leading-6 text-slate-800 placeholder:text-slate-500 focus:outline-0 focus:ring-0 focus:ring-offset-0"
                name="search"
                placeholder="Pesquise por cassinos, slots, bônus e promoções.."
                type="search"
                value=""
                @input="checkerIptval()"
            />

            <div
                class="absolute top-16 max-h-96 w-full overflow-y-scroll rounded-md bg-white drop-shadow-sm"
            >
              <div
                  v-for="(items, category) in datas"
                  :key="category"
                  class="search-hits p-4"
              >
                <h2 class="text-xl text-slate-600 font-semibold mb-3">
                  {{ category }}
                </h2>
                <div v-for="item in items" :key="item.src" class="">
                  <a
                      :href="item.src"
                      class="text-md font-medium flex items-center p-4 hover:bg-slate-100 leading-snug border-b border-b-slate-200"
                  >
                    <div
                        v-if="item.img"
                        class="rounded-sm flex h-8 w-8 items-center overflow-hidden mr-3"
                    >
                      <img
                          :alt="item.title"
                          :src="item.img"
                          class=""
                          height="64"
                          width="64"
                      />
                    </div>
                    <div>
                      {{ item.title }}
                      <span class="text-xs2 block text-gray-400">
                        <p
                            class="text-xs2 text-gray-400 font-semibold uppercase mt-1"
                        >
                          {{ item.content }}
                        </p>
                      </span>
                    </div>
                    <div
                        class="bg-slate-300 p-1 ml-auto rounded-full flex items-center justify-center"
                    >
                      <svg
                          class="h-4 w-4"
                          fill="none"
                          height="24"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <line x1="5" x2="19" y1="12" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {searchByTitle} from "../util/datas/seachData";

export default {
  props: {},
  data() {
    return {
      queryStr: "",
      datas: {},
      // datas: {
      //   Análises: [
      //     {
      //       src: "/reviews/joya-casino",
      //       img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
      //       title: "Joya",
      //       content: "1 de maio de 2024",
      //       flg: 0,
      //     },
      //     {
      //       src: "/reviews/winz",
      //       img: require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
      //       title: "Winz",
      //       content: "28 de maio de 2024",
      //       flg: 0,
      //     },
      //     {
      //       src: "/reviews/citobet",
      //       img:require("@/assets/AdvertisingReplacement/ZB-Logo320_200-4.png"),
      //       title: "Citobet",
      //       content: "1 de maio de 2024",
      //       flg: 0,
      //     },
      //   ],
      //
      //   Páginas: [
      //     {
      //       src: "/cassino-ao-vivo",
      //       img: null,
      //       title: "Cassino Ao Vivo",
      //       content: "23 de maio de 2024",
      //       flg: 0,
      //     },
      //     {
      //       src: "/slots-com-alto-rtp",
      //       img: "https://www.datocms-assets.com/102406/1689963022-slots-de-cassino-rtp-alto.jpg",
      //       title: "Slots Com Alto RTP",
      //       content: "29 de maio de 2024",
      //       flg: 0,
      //     },
      //     {
      //       src: "/apostas",
      //       img: null,
      //       title: "Sites de Apostas",
      //       content: "15 de maio de 2024",
      //       flg: 0,
      //     },
      //     {
      //       src: "/crash",
      //       img: null,
      //       title: "Crash Games Aposta",
      //       content: "29 de maio de 2024",
      //       flg: 0,
      //     },
      //   ],
      //
      //   Notícias: [
      //     {
      //       src: "/noticias/aviator-parimatch", // 无页面
      //       img: "/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1698335051-aviator-parimatch.jpg&w=128&q=75",
      //       title: "Aviator Parimatch",
      //       content: "24 de maio de 2024",
      //       flg: 0,
      //     },
      //     {
      //       src: "/noticias/aviator-betano",
      //       img: "/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F102406%2F1698410713-aviator-betano.jpg&w=128&q=75",
      //       title: "Aviator Betano",
      //       content: "2 de maio de 2024",
      //       flg: 0,
      //     },
      //   ],
      //
      //   Slots: [
      //     {
      //       src: "/slots/action-boost-gladiator",
      //       img: "https://www.datocms-assets.com/102406/1705571218-logo-slot-action-boost-gladiator.png",
      //       title: "Action Boost Gladiator",
      //       content: "29 de maio de 2024",
      //       flg: 0,
      //     },
      //     {
      //       src: "/slots/spin-and-win",
      //       img: "https://www.datocms-assets.com/102406/1706843916-spind-and-win.png",
      //       title: "Spin and Win",
      //       content: "1 de maio de 2024",
      //       flg: 0,
      //     },
      //     {
      //       src: "/slots/wisdom-of-athena",
      //       img: "https://www.datocms-assets.com/102406/1715678514-jogo-de-cassino-wisdom-of-athena.webp",
      //       title: "Wisdom of Athena",
      //       content: "29 de maio de 2024",
      //       flg: 0,
      //     },
      //   ],
      // },
    };
  },
  methods: {
    close() {
      this.$emit("closeSearchModal");
    },

    checkerIptval() {
      if (this.queryStr === "") {
        this.datas = {};
      } else {
        this.datas = searchByTitle(this.queryStr);
      }
    },
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  destroyed() {
    document.body.style.overflow = "";
  },
};
</script>
<style scoped></style>
