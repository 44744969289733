<template>
  <div class="text-base-content relative bg-primary px-6 pt-16 lg:px-0">
    <div class="mx-auto flex max-w-5xl flex-col gap-12 lg:flex-row lg:gap-12">
      <div class="w-full flex-col lg:w-1/4">
        <span class="block pb-4 font-bold uppercase text-heading"
        >Sites confiáveis</span
        >
        <ul>
          <li>
            <a
                class="block py-2 text-sm hover:text-dark-blue"
                href="/melhores-cassinos-online"
            >Melhores Cassino online</a
            >
          </li>
          <li>
            <a class="block py-2 text-sm hover:text-dark-blue" href="/bonus"
            >Bônus</a
            >
          </li>
          <li>
            <a
                class="block py-2 text-sm hover:text-dark-blue"
                href="/cassino-ao-vivo"
            >Cassino ao vivo</a
            >
          </li>
          <li>
            <a class="block py-2 text-sm hover:text-dark-blue" href="/slots"
            >Slots</a
            >
          </li>
          <li>
            <a class="block py-2 text-sm hover:text-dark-blue" href="/crash"
            >Crash Games</a
            >
          </li>
          <li>
            <a class="block py-2 text-sm hover:text-dark-blue" href="/gameshows"
            >Game Shows</a
            >
          </li>
          <li>
            <a class="block py-2 text-sm hover:text-dark-blue" href="/reviews"
            >Reviews</a
            >
          </li>
          <li>
            <a class="block py-2 text-sm hover:text-dark-blue" href="/apostas"
            >Sites de apostas</a
            >
          </li>
        </ul>
      </div>
      <div class="flex-col lg:w-1/4">
        <span class="block pb-4 font-bold uppercase text-heading">Reviews</span>
        <ul>
          <li>
            <a
                class="block py-2 text-sm hover:text-dark-blue"
                href="/reviews/apostarei"
            >ApostaRei</a
            >
          </li>
          <li>
            <a
                class="block py-2 text-sm hover:text-dark-blue"
                href="/reviews/qbet"
            >Qbet</a
            >
          </li>
          <li>
            <a
                class="block py-2 text-sm hover:text-dark-blue"
                href="/reviews/let-s-go-casino"
            >Let´s Go Casino</a
            >
          </li>
          <li>
            <a
                class="block py-2 text-sm hover:text-dark-blue"
                href="/reviews/casimba"
            >Casimba</a
            >
          </li>
          <li>
            <a
                class="block py-2 text-sm hover:text-dark-blue"
                href="/reviews/winz"
            >Winz</a
            >
          </li>
          <li>
            <a
                class="block py-2 text-sm hover:text-dark-blue"
                href="/reviews/sapphirebet"
            >SapphireBet</a
            >
          </li>
        </ul>
      </div>
      <div class="flex-col lg:w-1/4">
        <span class="block pb-4 font-bold uppercase text-heading"
        >Informação</span
        >
        <ul class="text-sm">
          <li>
            <a class="block py-2 hover:text-dark-blue" href="/sitemap.xml"
            >Sitemap</a
            >
          </li>
          <li>
            <a class="block py-2 hover:text-dark-blue" href="/sobre-nos"
            >Sobre nós</a
            >
          </li>
          <li>
            <a class="block py-2 hover:text-dark-blue" href="/jogo-responsavel"
            >Jogo Responsável</a
            >
          </li>
          <li>
            <a
                class="block py-2 hover:text-dark-blue"
                href="/politica-de-privacidade"
            >Política de Privacidade</a
            >
          </li>
        </ul>
      </div>
      <div class="flex-col lg:w-1/4">
        <span class="block pb-4 font-bold uppercase text-heading"
        >Detalhes do contato</span
        >
        <div class="text-sm leading-6">
          Webbase Media Limited <br/>International House <br/>Mdina Road,
          Mriehel <br/>BKR 3000, Malta <br/>C 103287 <br/>
        </div>
      </div>
    </div>
    <div class="mx-auto mt-12 max-w-5xl">
      <div class="w-60">
        <div class="flex gap-3">
          <a
              class="border-2 border-white rounded-full"
              href="https://casinos24.co"
              rel="noopener noreferrer"
              target="_blank"
              title="Colombia"
          ><img
              alt="Colombia"
              data-nimg="1"
              decoding="async"
              height="30"
              loading="lazy"
              src="@/assets/images/footer/colombia.svg"
              style="color: transparent"
              width="30"/></a
          ><a
            class="border-2 border-white rounded-full"
            href="https://casinos24.mx"
            rel="noopener noreferrer"
            target="_blank"
            title="Mexico"
        ><img
            alt="Mexico"
            data-nimg="1"
            decoding="async"
            height="30"
            loading="lazy"
            src="@/assets/images/footer/mexico.svg"
            style="color: transparent"
            width="30"/></a
        ><a
            class="border-2 border-white rounded-full"
            href="https://casinos24.cl"
            rel="noopener noreferrer"
            target="_blank"
            title="Chile"
        ><img
            alt="Chile"
            data-nimg="1"
            decoding="async"
            height="30"
            loading="lazy"
            src="@/assets/images/footer/chile.svg"
            style="color: transparent"
            width="30"
        /></a>
        </div>
      </div>
    </div>
    <ul
        class="mx-auto flex max-w-5xl flex-wrap items-center justify-center gap-4 pb-0 pt-10 lg:justify-start"
    >
      <li>
        <a
            aria-label="Ecogra"
            href="https://ecogra.org/"
            rel="nofollow noopener noreferrer"
            target="_blank"
        ><img
            alt="Ecogra"
            data-nimg="1"
            decoding="async"
            height="57"
            loading="lazy"
            src="@/assets/images/footer/ecogra_logo.webp"
            style="color: transparent"
            width="125"
        /></a>
      </li>
      <li>
        <img
            alt="18+"
            data-nimg="1"
            decoding="async"
            height="30"
            loading="lazy"
            src="@/assets/images/footer/18plus.webp"
            style="color: transparent"
            width="30"
        />
      </li>
      <li>
        <a
            aria-label="Jogadores Anonimos"
            href="https://jogadoresanonimos.com.br/"
            rel="nofollow noopener noreferrer"
            target="_blank"
        ><img
            alt="Jogadores Anonimos"
            data-nimg="1"
            decoding="async"
            height="34"
            loading="lazy"
            src="@/assets/images/footer/jogadores-anonimos.webp"
            style="color: transparent"
            width="34"
        /></a>
      </li>
    </ul>
    <div
        class="mx-auto mt-10 flex max-w-5xl flex-col-reverse items-center justify-center border-t border-yellow-400 py-8 pt-8 lg:mt-8 lg:flex-row"
    >
      <p class="mb-0 pb-0 text-center text-sm">
        ©
        <!-- -->2024<!-- -->
        Casinos24. Todos os direitos reservados.
      </p>
      <ul
          class="mb-6 flex items-center justify-center gap-2 text-white lg:mb-0 lg:ml-auto"
      >
        <li class="rounded-full bg-dark-blue p-2">
          <a
              aria-label="Facebook"
              href="https://www.facebook.com/people/Casinos24/100088722240402/"
              rel="noopener noreferrer nofollow"
              target="_blank"
              title="Facebook"
          >
            <svg
                fill="none"
                height="11"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                width="11"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"
              ></path>
            </svg
            >
          </a>
        </li>
        <li class="rounded-full bg-dark-blue p-2">
          <a
              aria-label="Instagram"
              href="https://www.instagram.com/casinos24/"
              rel="noopener noreferrer nofollow"
              target="_blank"
              title="Instagram"
          >
            <svg
                fill="none"
                height="11"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                width="11"
                xmlns="http://www.w3.org/2000/svg"
            >
              <rect height="20" rx="5" ry="5" width="20" x="2" y="2"></rect>
              <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
              <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"></line>
            </svg
            >
          </a>
        </li>
        <li class="rounded-full bg-dark-blue p-2">
          <a
              aria-label="Youtube"
              href="https://www.youtube.com/channel/UCcICSQQqXMrZLHiZt93V15Q"
              rel="noopener noreferrer nofollow"
              target="_blank"
              title="Youtube"
          >
            <svg
                fill="none"
                height="11"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                width="11"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"
              ></path>
              <polygon
                  points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"
              ></polygon>
            </svg
            >
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
