import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Casinos24 - Análise, conteúdos e dicas sobre os cassinos",
    component: () => import("@/views/melhores/br"),
    children: [],
    meta: {
      title: "cast",
    },
  },
  {
    path: "/ProhibitAccess",
    name: "casinos24.com.br/ProhibitAccess",
    component: () => import("@/views/melhores/ProhibitAccess"),
  },

  {
    path: "/404",
    name: "casinos24.com.br/404",
    meta: {
      title: "Page not found",
      isLogin: false,
    },
    component: () => import("@/views/melhores/404"),
  },

  {
    path: "*",
    redirect: "/404",
  },
  {
    path: "/melhores-cassinos-online",
    name: "10 Melhores Sites de Cassinos Online | Ranking Maio 2024",
    component: () => import("@/views/melhores/melhores-cassinos-online"),
    // children: [
    //   {
    //     path: "/melhores-cassinos-online/dinheiro-real",
    //     name: "dinheiro-real",
    //     component: () => import("@/views/melhores/children/dinheiro-real"),
    //   },
    // ],
  },
  {
    path: "/melhores-cassinos-online/dinheiro-real",
    name: "Melhores Sites de Cassino Online Com Dinheiro Real",
    component: () => import("@/views/melhores/children/dinheiro-real"),
  },
  {
    path: "/melhores-cassinos-online/sem-licenca",
    name: "sem-licenca",
    component: () => import("@/views/melhores/children/sem-licenca"),
  },
  {
    path: "/melhores-cassinos-online/sites-jogos-azar",
    name: "10 Melhores Sites de Jogos de Azar Online de 2024",
    component: () => import("@/views/melhores/children/sites-jogos-azar"),
  },
  {
    path: "/sites-jogos-azar",
    name: "sites-jogos-azar",
    component: () => import("@/views/melhores/children/sites-jogos-azar"),
  },
  {
    path: "/melhores-cassinos-online/pagam-rapido",
    name: "pagam-rapido",
    component: () => import("@/views/melhores/children/pagam-rapido"),
  },
  {
    path: "/melhores-cassinos-online/novos",
    name: "10 Melhores Novos Cassinos Online do Brasil em 2024",
    component: () => import("@/views/melhores/children/novos"),
  },
  {
    path: "/melhores-cassinos-online/mais-pagam",
    name: "5 Melhores Sites de Cassino Que Mais Pagam em 2024",
    component: () => import("@/views/melhores/children/mais-pagam"),
  },
  {
    path: "/melhores-cassinos-online/com-licenca",
    name: "com-licenca",
    component: () => import("@/views/melhores/children/com-licenca"),
  },
  {
    path: "/melhores-cassinos-online/limites-altos",
    name: "limites-altos",
    component: () => import("@/views/melhores/children/limites-altos"),
  },
  {
    path: "/melhores-cassinos-online/demo",
    name: "5 Melhores Sites de Cassinos Online Demo em 2024",
    component: () => import("@/views/melhores/children/demo"),
  },
  {
    path: "/melhores-cassinos-online/app",
    name: "Melhores sites de cassino com aplicativo em 2024",
    component: () => import("@/views/melhores/children/app"),
  },

  {
    path: "/melhores-cassinos-online/pagam-na-hora",
    name: "Melhores sites de cassino que pagam na hora em 2024",
    component: () => import("@/views/melhores/children/pagam-na-hora"),
  },
  {
    path: "/melhores-cassinos-online/novos-cassinos-pagando-no-cadastro",
    name: "Melhores novos cassinos pagando no cadastro em 2024",
    component: () =>
      import("@/views/melhores/children/novos-cassinos-pagando-no-cadastro"),
  },
  //
  {
    path: "/melhores-cassinos-online/torneio",
    name: "Melhores sites de cassino com torneio",
    component: () => import("@/views/melhores/children/torneio"),
  },
  {
    path: "/bonus",
    name: "12 Melhores Sites de Cassino Com Bônus de Boas-Vindas",
    component: () => import("@/views/bonus/bonus"),
  },
  {
    path: "/bonus/primeiro-deposito",
    name: "Melhores Sites de Cassino Bônus primeiro depósito em 2024",
    component: () => import("@/views/bonus/primeiro-deposito"),
  },
  {
    path: "/bonus/reembolso",
    name: "Melhores sites de Cassinos com reembolso em 2024",
    component: () => import("@/views/bonus/children/reembolso"),
  },
  {
    path: "/bonus/cashback",
    name: "4 Melhores Sites de Cassino Com Cashback em 2024",
    component: () => import("@/views/bonus/children/cashback"),
  },
  {
    path: "/bonus/vip",
    name: "5 Melhores Cassinos com Programas VIPs no Brasil - 2024",
    component: () => import("@/views/bonus/children/vip"),
  },
  {
    path: "/bonus/sem-rollover",
    name: "Melhores Cassino Online Sem Rollover no Bônus em 2024",
    component: () => import("@/views/bonus/children/sem-rollover"),
  },
  {
    path: "/bonus/sem-deposito",
    name: "sem-deposito",
    component: () => import("@/views/bonus/children/sem-deposito"),
  },
  {
    path: "/bonus/rodadas-gratis",
    name: "5 Melhores Cassinos com Rodadas Grátis no Cadastro 2024",
    component: () => import("@/views/bonus/children/rodadas-gratis"),
  },
  {
    path: "/rodadas-gratis",
    name: "rodadas-gratis",
    component: () => import("@/views/bonus/children/rodadas-gratis"),
  },
  {
    path: "/bonus/cadastro",
    name: "Melhores sites cassino com Bônus sem Depósito no cadastro",
    component: () => import("@/views/bonus/children/cadastro"),
  },
  {
    path: "/bonus/gratis-50-reais",
    name: "gratis-50-reais",
    component: () => import("@/views/bonus/children/gratis-50-reais"),
  },
  {
    path: "/slots",
    name: "Caça-Níqueis Online: Os 10 Melhores Sites Para Jogar Slots",
    component: () => import("@/views/slots/slots"),
  },
  {
    path: "/slots/ganesha-fortune",
    name: "Ganesha Fortune: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/ganesha-fortune"),
  },
  {
    path: "/slots/rotten",
    name: "Rotten Slot: Análise de Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/rotten"),
  },
  {
    path: "/slots/ganesha-gold",
    name: "Ganesha Gold: Jogo do Elefante Demo (Grátis) e Como Jogar",
    component: () => import("@/views/slots/children/ganesha-gold"),
  },
  {
    path: "/slots/double-fortune",
    name: "Double Fortune Slot: Análise de Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/double-fortune"),
  },
  {
    path: "/slots/fortune-mouse",
    name: "Fortune Mouse: Jogo do Rato Demo (Grátis) e Como Jogar",
    component: () => import("@/views/slots/fortune-mouse"),
  },
  {
    path: "/slots/wisdom-of-athena",
    name: "Wisdom of Athena: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/wisdom-of-athena"),
  },
  {
    path: "/slots/leprechaun-riches",
    name: "Leprechaun Riches Slot: Análise Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/leprechaun-riches"),
  },
  {
    path: "/slots/fortune-tiger",
    name: "Fortune Tiger: Jogo do Tigre Demo (Grátis) e Como Jogar",
    component: () => import("@/views/slots/children/fortune-tiger"),
  },
  {
    path: "/plataformas-de-jogos-novas",
    name: "5 Melhores Cassinos Online que Aceitam Criptomoedas | 2024 ",
    component: () =>
      import(
        "@/views/cripto-cassinos/plataforma/plataformas-de-jogos-novas.vue"
      ),
  },
  {
    path: "/slots/20-coins",
    name: "20 Coins: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/20-coins"),
  },
  {
    path: "/slots/dragon-hatch-2",
    name: "Dragon Hatch 2: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/dragon-hatch-2"),
  },
  {
    path: "/slots/rip-city",
    name: "RIP City: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/rip-city"),
  },
  {
    path: "/slots/zeus-vs-hades",
    name: "Zeus vs Hades: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/zeus-vs-hades"),
  },
  {
    path: "/slots/fortune-dragon",
    name: "Fortune Dragon: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/fortune-dragon"),
  },
  {
    path: "/slots/wanted-dead-or-a-wild",
    name: "Wanted Dead or a Wild: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/wanted-dead-or-a-wild"),
  },
  {
    path: "/slots/hand-of-anubis",
    name: "Hand of Anubis: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/hand-of-anubis"),
  },
  {
    path: "/slots/chaos-crew",
    name: "Chaos Crew: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/chaos-crew"),
  },
  {
    path: "/slots/wild-bandito",
    name: "Wild Bandito: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/wild-bandito"),
  },
  {
    path: "/slots/wild-fireworks",
    name: "Wild Fireworks: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/wild-fireworks"),
  },
  {
    path: "/slots/action-boost-gladiator",
    name: "Action Boost Gladiator: Jogo Demo (Grátis) Como Jogar e Mais",
    component: () => import("@/views/slots/children/action-boost-gladiator"),
  },
  {
    path: "/slots/lucky-piggy",
    name: "Lucky Piggy: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/lucky-piggy"),
  },

  {
    path: "/slots/sweet-bonanza",
    name: "Sweet Bonanza Slot: Análise, Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/sweet-bonanza"),
  },
  {
    path: "/slots/gates-of-olympus",
    name: "Gates of Olympus: Jogo do Velho do Raio Demo (Grátis)",
    component: () => import("@/views/slots/children/gates-of-olympus"),
  },
  {
    path: "/slots/tree-of-fortune",
    name: "Tree of Fortune: Jogo da Árvore Cassino que Ganha Dinheiro",
    component: () => import("@/views/slots/children/tree-of-fortune"),
  },
  {
    path: "/slots/dragon-hero",
    name: "Dragon Hero Slot: Análise de Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/dragon-hero"),
  },

  {
    path: "/slots/madame-fortune",
    name: "Madame Fortune Slot: Análise Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/madame-fortune"),
  },
  {
    path: "/slots/the-great-icescape",
    name: "The Great Icescape: Jogo do Pinguim | Como Jogar, Dicas e +",
    component: () => import("@/views/slots/children/the-great-icescape"),
  },
  {
    path: "/slots/piggy-riches",
    name: "Piggy Riches Slot: Análise de Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/piggy-riches"),
  },
  {
    path: "/slots/fortune-gods",
    name: "Fortune Gods Slot: Análise de Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/fortune-gods"),
  },
  {
    path: "/slots/midas-fortune",
    name: "Midas Fortune Slot: Análise de Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/midas-fortune"),
  },
  {
    path: "/slots/melhor-horario-para-jogar-gates-of-oylmpus",
    name: "Melhores Horários Para Jogar Gates of Olympus em 2024",
    component: () =>
      import(
        "@/views/slots/children/melhor-horario-para-jogar-gates-of-oylmpus"
      ),
  },
  {
    path: "/slots/dragon-hatch",
    name: "Dragon Hatch: Jogo do Dragão Demo (Grátis) e Como Jogar",
    component: () => import("@/views/slots/children/dragon-hatch"),
  },
  {
    path: "/slots/fortune-mouse",
    name: "Fortune Mouse: Jogo do Rato Demo (Grátis) e Como Jogar",
    component: () => import("@/views/slots/children/fortune-mouse"),
  },
  {
    path: "/slots/caishen-wins",
    name: "caishen-wins",
    component: () => import("@/views/slots/children/caishen-wins"),
  },
  {
    path: "/slots/fortune-rabbit",
    name: "Fortune Rabbit: Jogo do Coelho Demo (Grátis) e Como Jogar",
    component: () => import("@/views/slots/children/fortune-rabbit"),
  },
  {
    path: "/slots/fortune-tiger",
    name: "Fortune Tiger: Jogo do Tigre Demo (Grátis) e Como Jogar",
    component: () => import("@/views/slots/children/fortune-tiger"),
  },
  {
    path: "/slots/fortune-ox",
    name: "Fortune OX: Jogo do Touro Demo (Grátis) e Como Jogar",
    component: () => import("@/views/slots/children/fortune-ox"),
  },

  {
    path: "/slots/big-bass-bonanza",
    name: "Big Bass Bonanza: Jogo Pescador Demo (Grátis) e Como Jogar",
    component: () => import("@/views/slots/children/big-bass-bonanza"),
  },
  {
    path: "/slots/tsar-treasures",
    name: "Tsar Treasures: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/tsar-treasures"),
  },
  {
    path: "/slots/santas-gift-rush",
    name: " Santa's Gift Rush: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/santas-gift-rush"),
  },
  {
    path: "/slots/buffalo-win",
    name: "Buffalo Win: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/buffalo-win"),
  },
  {
    path: "/slots/hip-hop-panda",
    name: "Hip Hop Panda Slot: Análise, Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/hip-hop-panda"),
  },
  {
    path: "/slots/songkran-splash",
    name: "Songkran Splash: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/songkran-splash"),
  },
  {
    path: "/slots/bikini-paradise",
    name: "Bikini Paradise Slot: Análise, Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/bikini-paradise"),
  },
  {
    path: "/slots/piggy-gold",
    name: "Piggy Gold Slot: Análise, Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/piggy-gold"),
  },
  {
    path: "/slots/fruit-party",
    name: "Fruit Party Slot: Análise, Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/fruit-party"),
  },
  {
    path: "/slots/mystical-spirits",
    name: "Mystical Spirits: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/mystical-spirits"),
  },
  {
    path: "/slots/jewels-of-prosperity",
    name: "Jewels of Prosperity: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/jewels-of-prosperity"),
  },
  {
    path: "/slots/hot-pepper",
    name: "Hot Pepper: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/hot-pepper"),
  },
  {
    path: "/slots/wild-wild-bananas",
    name: "Wild Wild Bananas: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/wild-wild-bananas"),
  },
  {
    path: "/slots/queen-of-ice",
    name: "Queen of ice: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/queen-of-ice"),
  },
  {
    path: "/slots/candy-bonanza",
    name: "Candy Bonanza: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/candy-bonanza"),
  },
  {
    path: "/slots/money-train-2",
    name: "Money Train 2: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/money-train-2"),
  },
  {
    path: "/slots/werewolf-s-hunt",
    name: "Werewolf's Hunt: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/werewolf-s-hunt"),
  },
  {
    path: "/slots/gonzos-quest",
    name: "Gonzo's Quest: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/gonzos-quest"),
  },
  {
    path: "/slots/spin-and-win",
    name: "Spin and Win: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/spin-and-win"),
  },
  {
    path: "/slots/book-of-the-fallen",
    name: "Book of The Fallen: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/book-of-the-fallen"),
  },

  {
    path: "/slots/infective-wild",
    name: "Infective Wild Slot: Análise, Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/infective-wild"),
  },
  {
    path: "/slots/monster-superlanche",
    name: "Monster superlanche: Jogo Demo (Grátis), Como Jogar e Mais",
    component: () => import("@/views/slots/children/monster-superlanche"),
  },

  {
    path: "/slots/double-fortune",
    name: "Double Fortune Slot: Análise de Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/double-fortune"),
  },

  {
    path: "/slots/rasputin-megaways",
    name: "Rasputin Megaways Slot: Análise Como Jogar, Como Funciona",
    component: () => import("@/views/slots/children/rasputin-megaways"),
  },
  {
    path: "/slots/piggy-bank-megaways",
    name: "Piggy Bank Megaways Slot: Jogar Jogo do Cofrinho",
    component: () => import("@/views/slots/children/piggy-bank-megaways"),
  },

  {
    path: "/crash",
    name: "Jogos do Tipo Crash Aposta | 10 Melhores Sites Para Jogar",
    component: () => import("@/views/crash/crash"),
    children: [],
  },

  {
    path: "/crash/plinko",
    name: "Jogar Plinko Online | Fature Com o Jogo da Bolinha Cassino",
    component: () => import("@/views/crash/children/plinko"),
    children: [],
  },
  {
    path: "/crash/jetx3",
    name: "Jogar JetX3 Online | Fature Muito Dinheiro Com o Jetx3",
    component: () => import("@/views/crash/children/jetx3"),
    children: [],
  },
  {
    path: "/crash/crash-x",
    name: "Jogar Crash-x Online | Fature Muito Dinheiro Com Crash X",
    component: () => import("@/views/crash/children/crash-x"),
    children: [],
  },
  {
    path: "/crash/balloon",
    name: "Jogar Balloon Online | Fature Muito Com Jogo do Balãozinho",
    component: () => import("@/views/crash/children/balloon"),
    children: [],
  },
  {
    path: "/crash/mines",
    name: "Jogar Mines Online | Fature Muito Com o Jogo da Bombinha",
    component: () => import("@/views/crash/children/mines"),
    children: [],
  },
  {
    path: "/crash/aviator",
    name: "Aviator Aposta | Ganhe Dinheiro Com o Jogo do Aviãozinho",
    component: () => import("@/views/crash/children/aviator"),
    children: [],
  },
  {
    path: "/crash/jetx",
    name: "JetX Aposta | Ganhe Dinheiro Com Jogo do Foguetinho Online ",
    component: () => import("@/views/crash/children/jetx"),
    children: [],
  },
  {
    path: "/crash/spaceman",
    name: "Spaceman Aposta | Ganhe Dinheiro Com Jogo do Astronauta",
    component: () => import("@/views/crash/children/spaceman"),
    children: [],
  },
  {
    path: "/crash/zeppelin",
    name: "Jogar Zeppelin Crash Aposta | 8 Melhores Sites Com Bônus",
    component: () => import("@/views/crash/children/zeppelin"),
    children: [],
  },

  {
    path: "/noticias",
    name: "Blog de Cassino | Os Melhores Conteúdos Sobre Cassino",
    component: () => import("@/views/noticias/noticias"),
    redirect: { name: "Blog de Cassino | Os Melhores Conteúdos Sobre Cassino" },
    children: [
      {
        path: "rodadas-gratis-fortune-ox",
        name: "Rodadas grátis no Fortune OX | Melhores Plataformas",
        component: () =>
          import("@/views/noticias/child/rodadas-gratis-fortune-ox"),
      },
      {
        path: "rodadas-gratis-fortune-tiger",
        name: "Rodadas grátis no Fortune Tiger | Melhores Plataformas",
        component: () =>
          import("@/views/noticias/child/rodadas-gratis-fortune-tiger"),
      },
      {
        path: "fortune-mouse-ganhos",
        name: "Fortune Mouse: Veja os maiores ganhos que já aconteceram",
        component: () => import("@/views/noticias/child/fortune-mouse-ganhos"),
      },
      {
        path: "fortune-rabbit-ganhos",
        name: "Fortune Rabbit: Veja os maiores ganhos que já aconteceram",
        component: () => import("@/views/noticias/child/fortune-rabbit-ganhos"),
      },
      {
        path: "fortune-ox-ganhos",
        name: "Fortune OX: Veja os maiores ganhos que já aconteceram",
        component: () => import("@/views/noticias/child/fortune-ox-ganhos"),
      },
      {
        path: "fortune-tiger-ganhos",
        name: "Fortune Tiger: Veja os maiores ganhos que já aconteceram",
        component: () => import("@/views/noticias/child/fortune-tiger-ganhos"),
      },
      {
        path: "dragon-hatch-download",
        name: "Dragon Hatch Download | Cuidado Com Golpes ao Baixar",
        component: () => import("@/views/noticias/child/dragon-hatch-download"),
      },
      {
        path: "fortune-mouse-download",
        name: "Fortune Mouse Download | Cuidado Com Golpes ao Baixar",
        component: () =>
          import("@/views/noticias/child/fortune-mouse-download"),
      },
      {
        path: "fortune-rabbit-download",
        name: "Fortune Rabbit Download | Cuidado Com Golpes ao Baixar",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-download"),
      },
      {
        path: "fortune-ox-download",
        name: "Fortune OX Download | Cuidado Com Golpes ao Baixar",
        component: () => import("@/views/noticias/child/fortune-ox-download"),
      },
      {
        path: "fortune-tiger-download",
        name: "Fortune Tiger Download | Cuidado Com Golpes ao Baixar",
        component: () =>
          import("@/views/noticias/child/fortune-tiger-download"),
      },
      {
        path: "link-oficial-dragon-hatch",
        name: "ᐉ Link Confiável Para Jogar Dragon Hatch | Jogo do Dragão",
        component: () =>
          import("@/views/noticias/child/link-oficial-dragon-hatch"),
      },
      {
        path: "link-oficial-fortune-mouse",
        name: "ᐉ Link Confiável Para Jogar Fortune Mouse | Jogo do Ratinho",
        component: () =>
          import("@/views/noticias/child/link-oficial-fortune-mouse"),
      },
      {
        path: "link-oficial-fortune-rabbit",
        name: "ᐉ Link Confiável Para Jogar Fortune Rabbit | Jogo do Coelho",
        component: () =>
          import("@/views/noticias/child/link-oficial-fortune-rabbit"),
      },
      {
        path: "link-oficial-fortune-ox",
        name: "ᐉ Link Confiável Para Jogar Fortune OX | Jogo do Touro",
        component: () =>
          import("@/views/noticias/child/link-oficial-fortune-ox"),
      },
      {
        path: "link-oficial-fortune-tiger",
        name: "ᐉ Link Confiável Para Jogar Fortune Tiger | Jogo do Tigre",
        component: () =>
          import("@/views/noticias/child/link-oficial-fortune-tiger"),
      },
      {
        path: "dragon-hatch-plataforma-que-paga",
        name: "Dragon Hatch Plataforma que Paga | Conheça as 5 Melhores",
        component: () =>
          import("@/views/noticias/child/dragon-hatch-plataforma-que-paga"),
      },
      {
        path: "fortune-mouse-plataforma-que-paga",
        name: "Fortune Mouse Plataforma que Paga | Conheça as 5 Melhores",
        component: () =>
          import("@/views/noticias/child/fortune-mouse-plataforma-que-paga"),
      },
      {
        path: "fortune-rabbit-plataforma-que-paga",
        name: "Fortune Rabbit Plataforma que Paga | Conheça as 5 Melhores",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-plataforma-que-paga"),
      },
      {
        path: "fortune-ox-plataforma-que-paga",
        name: "Fortune OX Plataforma que Paga | Conheça as 5 Melhores",
        component: () =>
          import("@/views/noticias/child/fortune-ox-plataforma-que-paga"),
      },
      {
        path: "fortune-tiger-plataforma-que-paga",
        name: "Fortune Tiger Plataforma que Paga | Conheça as 5 Melhores",
        component: () =>
          import("@/views/noticias/child/fortune-tiger-plataforma-que-paga"),
      },
      {
        path: "dragon-hatch-deposito-de-3-reais",
        name: "Dragon Hatch Depósito 3 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/dragon-hatch-deposito-de-3-reais"),
      },
      {
        path: "dragon-hatch-deposito-de-2-reais",
        name: "Dragon Hatch Depósito 2 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/dragon-hatch-deposito-de-2-reais"),
      },
      {
        path: "fortune-mouse-deposito-de-3-reais",
        name: "Fortune Mouse Depósito 3 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-mouse-deposito-de-3-reais"),
      },
      // {
      //   path: "fortune-mouse-deposito-de-3-reais",
      //   name: "fortune-mouse-deposito-de-3-reais",
      //   component: () =>
      //     import("@/views/noticias/child/fortune-mouse-deposito-de-3-reais"),
      // },
      {
        path: "fortune-mouse-deposito-de-2-reais",
        name: "Fortune Mouse Depósito 2 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-mouse-deposito-de-2-reais"),
      },
      // 以下是 pagina/4
      {
        path: "fortune-rabbit-deposito-de-5-reais",
        name: "Fortune Rabbit Depósito 5 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-deposito-de-5-reais"),
      },
      {
        path: "como-jogar-fortune-rabbit-no-celular",
        name: "Como jogar Fortune Rabbit no celular em 2024? Cuidado!",
        component: () =>
          import("@/views/noticias/child/como-jogar-fortune-rabbit-no-celular"),
      },
      {
        path: "fortune-tiger-deposito-de-1-real",
        name: "Fortune Tiger Depósito 1 Real: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-tiger-deposito-de-1-real"),
      },
      {
        path: "minutos-pagantes-dragon-hatch",
        name: "Minutos Pagantes do Dragon Hatch em 2024 [REVELADO]",
        component: () =>
          import("@/views/noticias/child/minutos-pagantes-dragon-hatch"),
      },
      {
        path: "minutos-pagantes-fortune-mouse",
        name: "Minutos Pagantes do Fortune Mouse em 2024 [REVELADO]",
        component: () =>
          import("@/views/noticias/child/minutos-pagantes-fortune-mouse"),
      },
      {
        path: "minutos-pagantes-fortune-rabbit",
        name: "Minutos Pagantes do Fortune Rabbit em 2024 [REVELADO]",
        component: () =>
          import("@/views/noticias/child/minutos-pagantes-fortune-rabbit"),
      },
      {
        path: "minutos-pagantes-fortune-ox",
        name: "Minutos Pagantes do Fortune OX em 2024 [REVELADO]",
        component: () =>
          import("@/views/noticias/child/minutos-pagantes-fortune-ox"),
      },
      {
        path: "minutos-pagantes-fortune-tiger",
        name: "Minutos Pagantes do Fortune Tiger em 2024 [REVELADO]",
        component: () =>
          import("@/views/noticias/child/minutos-pagantes-fortune-tiger"),
      },
      {
        path: "fortune-rabbit-nao-consigo-sacar",
        name: "Fortune Rabbit Não Consigo Sacar, que pode ser? Atualizado!",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-nao-consigo-sacar"),
      },
      {
        path: "fortune-rabbit-parou-de-pagar",
        name: "Fortune Rabbit Parou de Pagar em 2024? Segredo Revelado!",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-parou-de-pagar"),
      },
      {
        path: "fortune-rabbit-tem-na-playstore",
        name: "Será Que o Fortune Rabbit Tem na Playstore? CUIDADO!",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-tem-na-playstore"),
      },
      {
        path: "fortune-rabbit-esta-pagando",
        name: "Fortune Rabbit está pagando hoje? Realmente Paga em 2024?",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-esta-pagando"),
      },
      {
        path: "fortune-rabbit-esta-fora-do-ar",
        name: "Fortune Rabbit Está For do Ar? Não Abre? Veja o Que Fazer!",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-esta-fora-do-ar"),
      },
      {
        path: "fortune-rabbit-quanto-vale-cada-figura",
        name: "Fortune Rabbit Quanto Vale Cada Figura? Quanto Paga?",
        component: () =>
          import(
            "@/views/noticias/child/fortune-rabbit-quanto-vale-cada-figura"
          ),
      },
      {
        path: "fortune-rabbit-qual-plataforma-jogar",
        name: "Fortune Rabbit: Qual Melhor Plataforma? Onde Jogar em 2024?",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-qual-plataforma-jogar"),
      },
      {
        path: "fortune-rabbit-quanto-tempo-demora-para-cair-na-conta",
        name: "Fortune Rabbit Quanto Tempo Demora Para Cair na Conta?",
        component: () =>
          import(
            "@/views/noticias/child/fortune-rabbit-quanto-tempo-demora-para-cair-na-conta"
          ),
      },
      {
        path: "fortune-rabbit-bonus-de-cadastro",
        name: "Fortune Rabbit Com Bônus de Cadastro | 3 Melhores Sites",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-bonus-de-cadastro"),
      },
      {
        path: "como-funciona-a-roleta",
        name: "Entenda Como funciona a Roleta | Casinos24",
        component: () =>
          import("@/views/noticias/child/como-funciona-a-roleta"),
      },
      {
        path: "even-na-roleta",
        name: "Veja o que significa Even na Roleta Online | Casinos24",
        component: () => import("@/views/noticias/child/even-na-roleta"),
      },
      {
        path: "quanto-paga-cada-numero-da-roleta",
        name: "Veja Quanto Paga cada número na Roleta Online | Casinos24",
        component: () =>
          import("@/views/noticias/child/quanto-paga-cada-numero-da-roleta"),
      },
      {
        path: "double-blaze",
        name: "Double Blaze | Com funciona, Como Ganhar, Dicas e Mais",
        component: () => import("@/views/noticias/child/double-blaze"),
      },
      {
        path: "crash-blaze",
        name: "Crash Blaze | Como Funciona, Como Ganhar, Dicas e Mais",
        component: () => import("@/views/noticias/child/crash-blaze"),
      },
      {
        path: "blaze-jogos",
        name: "Descubra Quais os Jogos da Blaze Mais Famosos em 2024",
        component: () => import("@/views/noticias/child/blaze-jogos"),
      },
      {
        path: "como-jogar-cassino",
        name: "Entenda Como Jogar Cassino Online do Jeito Certo em 2023",
        component: () => import("@/views/noticias/child/como-jogar-cassino"),
      },
      {
        path: "quanto-paga-0-roleta",
        name: "Entenda Quanto Paga o 0 na Roleta Online | Casinos24",
        component: () => import("@/views/noticias/child/quanto-paga-0-roleta"),
      },
      {
        path: "melhor-horario-para-jogar-dragon-hatch",
        name: "Melhor Horário Para Jogar Dragon Hatch a Noite, Tarde, Dia",
        component: () =>
          import(
            "@/views/noticias/child/melhor-horario-para-jogar-dragon-hatch"
          ),
      },
      {
        path: "melhor-horario-para-jogar-fortune-ox",
        name: "Melhor Horário Para Jogar Fortune OX a Noite, Tarde e de Dia",
        component: () =>
          import("@/views/noticias/child/melhor-horario-para-jogar-fortune-ox"),
      },
      {
        path: "melhor-horario-para-jogar-fortune-mouse",
        name: "Melhor Horário Para Jogar Fortune Mouse a Noite, Tarde, Dia",
        component: () =>
          import(
            "@/views/noticias/child/melhor-horario-para-jogar-fortune-mouse"
          ),
      },
      {
        path: "melhor-horario-para-jogar-fortune-rabbit",
        name: "Melhor Horário Para Jogar Fortune Rabbit a Noite, Tarde, Dia",
        component: () =>
          import(
            "@/views/noticias/child/melhor-horario-para-jogar-fortune-rabbit"
          ),
      },
      {
        path: "quem-da-as-cartas-no-cassino",
        name: "Entenda Quem da as Cartas nos Jogos de Cassinos Online",
        component: () =>
          import("@/views/noticias/child/quem-da-as-cartas-no-cassino"),
      },
      {
        path: "como-jogar-slots-caca-niqueis",
        name: "Entenda Como Jogar Caça-Níqueis ou Slots do Jeito Certo",
        component: () =>
          import("@/views/noticias/child/como-jogar-slots-caca-niqueis"),
      },
      {
        path: "o-que-sao-jackpots",
        name: "O que é Jackpot, Como funciona e Estratégias Para Ganhar",
        component: () => import("@/views/noticias/child/o-que-sao-jackpots"),
      },
      {
        path: "fortune-tiger-deposito-de-5-reais",
        name: "Fortune Tiger Depósito 5 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-tiger-deposito-de-5-reais"),
      },
      {
        path: "como-jogar-fortune-tiger-no-celular",
        name: "Como jogar Fortune Tiger no celular em 2024? Passo a Passo",
        component: () =>
          import("@/views/noticias/child/como-jogar-fortune-tiger-no-celular"),
      },
      {
        path: "melhor-estrategia-para-jogar-crazy-time",
        name: "3 Melhores Estratégias Para Aplicar no Crazy Time e Ganhar",
        component: () =>
          import(
            "@/views/noticias/child/melhor-estrategia-para-jogar-crazy-time"
          ),
      },
      {
        path: "melhor-horario-para-jogar-crazy-time",
        name: "Melhor Horário Para Jogar Crazy Time a Noite, de Tarde e Dia",
        component: () =>
          import("@/views/noticias/child/melhor-horario-para-jogar-crazy-time"),
      },
      {
        path: "como-sacar-o-dinheiro-do-jogo-aviator",
        name: "Como Sacar o Dinheiro do jogo Aviator? Passo a Passo!",
        component: () =>
          import(
            "@/views/noticias/child/como-sacar-o-dinheiro-do-jogo-aviator"
          ),
      },
      {
        path: "melhores-horarios-para-jogar-spaceman",
        name: "Melhor Horário Para Jogar Spaceman a Noite, Tarde e Dia",
        component: () =>
          import(
            "@/views/noticias/child/melhores-horarios-para-jogar-spaceman"
          ),
      },
      {
        path: "fortune-tiger-nao-consigo-sacar",
        name: "Fortune Tiger Não Consigo Sacar, o que pode ser? Atualizado!",
        component: () =>
          import("@/views/noticias/child/fortune-tiger-nao-consigo-sacar"),
      },
      {
        path: "fortune-tiger-parou-de-pagar",
        name: "Fortune Tiger Parou de Pagar em 2024? Segredo Revelado!",
        component: () =>
          import("@/views/noticias/child/fortune-tiger-parou-de-pagar"),
      },
      {
        path: "handicap-negativo",
        name: "Handicap Negativo: O que é, Como Funciona, Quando Usar",
        component: () => import("@/views/noticias/child/handicap-negativo"),
      },
      {
        path: "como-pegar-vela-rosa-no-aviator",
        name: "Descubra Como Pegar Vela Rosa no Aviator | Atualizado 2023",
        component: () =>
          import("@/views/noticias/child/como-pegar-vela-rosa-no-aviator"),
      },
      {
        path: "fortune-tiger-estrategias",
        name: "5 Melhores Estratégias Para Ganhar no Fortune Tiger | 2024",
        component: () =>
          import("@/views/noticias/child/fortune-tiger-estrategias"),
      },
      {
        path: "melhor-horario-para-jogar-aviator",
        name: "Descubra o Melhor Horário Para Jogar Aviator em 2024",
        component: () =>
          import("@/views/noticias/child/melhor-horario-para-jogar-aviator"),
      },
      {
        path: "handicap-asiatico-00",
        name: "Handicap Asiático 0.0  nas Apostas | O que é e Como Funciona",
        component: () => import("@/views/noticias/child/handicap-asiatico-00"),
      },
      {
        path: "handicap-menos1",
        name: "Handicap -1 | O que é e Como Funciona o Handicap Menos 1",
        component: () => import("@/views/noticias/child/handicap-menos1"),
      },
      {
        path: "handicap2",
        name: "Handicap +2 | O que é e Como Funciona o Handicap Mais 2",
        component: () => import("@/views/noticias/child/handicap2"),
      },
      {
        path: "fortune-tiger-tem-na-playstore",
        name: "Será Que o Fortune Tiger Tem na Playstore? CUIDADO!",
        component: () =>
          import("@/views/noticias/child/fortune-tiger-tem-na-playstore"),
      },
      {
        path: "fortune-mouse-quanto-tempo-demora-para-cair-na-conta",
        name: "Fortune Mouse Quanto Tempo Demora Para Cair na Conta?",
        component: () =>
          import(
            "@/views/noticias/child/fortune-mouse-quanto-tempo-demora-para-cair-na-conta"
          ),
      },
      {
        path: "fortune-mouse-bonus-de-cadastro",
        name: "Fortune Mouse Com Bônus de Cadastro | 3 Melhores Sites",
        component: () =>
          import("@/views/noticias/child/fortune-mouse-bonus-de-cadastro"),
      },
      {
        path: "fortune-rabbit-estrategias",
        name: "5 Melhores Estratégias Para Ganhar no Fortune Rabbit | 2024",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-estrategias"),
      },
      {
        path: "fortune-rabbit-como-sacar",
        name: "Como Sacar o Dinheiro que ganhei jogando Fortune Rabbit?",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-como-sacar"),
      },
      {
        path: "fortune-rabbit-deposito-de-1-real",
        name: "Fortune Rabbit Depósito 1 Real: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-deposito-de-1-real"),
      },

      {
        path: "fortune-rabbit-deposito-de-3-reais",
        name: "Fortune Rabbit Depósito 3 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-deposito-de-3-reais"),
      },
      {
        path: "fortune-rabbit-deposito-de-2-reais",
        name: "Fortune Rabbit Depósito 2 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-deposito-de-2-reais"),
      },
      {
        path: "fortune-ox-deposito-de-3-reais",
        name: "Fortune OX Depósito 3 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-ox-deposito-de-3-reais"),
      },
      {
        path: "fortune-ox-deposito-de-2-reais",
        name: "Fortune OX Depósito 2 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-ox-deposito-de-2-reais"),
      },
      {
        path: "dragon-hatch-deposito-de-20-reais",
        name: "Dragon Hatch Depósito 20 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/dragon-hatch-deposito-de-20-reais"),
      },
      {
        path: "dragon-hatch-deposito-de-15-reais",
        name: "Dragon Hatch Depósito 15 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/dragon-hatch-deposito-de-15-reais"),
      },
      {
        path: "dragon-hatch-deposito-de-10-reais",
        name: "Dragon Hatch Depósito 10 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/dragon-hatch-deposito-de-10-reais"),
      },
      {
        path: "fortune-mouse-deposito-de-20-reais",
        name: "Fortune Mouse Depósito 20 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-mouse-deposito-de-20-reais"),
      },
      {
        path: "fortune-mouse-deposito-de-15-reais",
        name: "Fortune Mouse Depósito 15 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-mouse-deposito-de-15-reais"),
      },
      {
        path: "fortune-mouse-deposito-de-10-reais",
        name: "Fortune Mouse Depósito 10 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-mouse-deposito-de-10-reais"),
      },
      {
        path: "fortune-rabbit-deposito-de-20-reais",
        name: "Fortune Rabbit Depósito 20 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-deposito-de-20-reais"),
      },
      {
        path: "fortune-rabbit-deposito-de-15-reais",
        name: "Fortune Rabbit Depósito 15 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-deposito-de-15-reais"),
      },
      {
        path: "fortune-rabbit-deposito-de-10-reais",
        name: "Fortune Rabbit Depósito 10 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-rabbit-deposito-de-10-reais"),
      },
      {
        path: "fortune-ox-deposito-de-20-reais",
        name: "Fortune OX Depósito 20 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-ox-deposito-de-20-reais"),
      },
      {
        path: "fortune-ox-deposito-de-15-reais",
        name: "Fortune OX Depósito 15 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-ox-deposito-de-15-reais"),
      },
      {
        path: "fortune-ox-deposito-de-10-reais",
        name: "Fortune OX Depósito 10 Reais: 5 Sites Para Jogar em 2024",
        component: () =>
          import("@/views/noticias/child/fortune-ox-deposito-de-10-reais"),
      },
      {
        path: "plataforma-20-reais-tigre",
        name: "5 Plataformas 20 reais para jogar no tigre | Fortune Tiger",
        component: () =>
          import("@/views/noticias/child/plataforma-20-reais-tigre"),
      },
      {
        path: "plataforma-15-reais-tigre",
        name: "5 Plataformas 15 reais para jogar no tigre | Fortune Tiger",
        component: () =>
          import("@/views/noticias/child/plataforma-15-reais-tigre"),
      },
      {
        path: "plataforma-10-reais-tigre",
        name: "5 Plataformas 10 reais para jogar no tigre | Fortune Tiger",
        component: () =>
          import("@/views/noticias/child/plataforma-10-reais-tigre"),
      },
      {
        path: "plataforma-3-reais-tigre",
        name: "5 Plataformas de 3 reais para jogar no tigre | Fortune Tiger",
        component: () =>
          import("@/views/noticias/child/plataforma-3-reais-tigre"),
      },
      {
        path: "plataformas-2-reais-tigre",
        name: "5 Plataformas de 2 reais para jogar no tigre | Fortune Tiger",
        component: () =>
          import("@/views/noticias/child/plataformas-2-reais-tigre"),
      },
      {
        path: "melhores-jogos-de-jackpot",
        name: "10 Melhores Jogos de Jackpot em 2024 | Ranking Atualizado",
        component: () =>
          import("@/views/noticias/child/melhores-jogos-de-jackpot"),
      },
      {
        path: "como-jogar-baccarat",
        name: "Como jogar Baccarat online do jeito certo? Aprenda já!",
        component: () => import("@/views/noticias/child/como-jogar-baccarat"),
      },
      {
        path: "como-funciona-torneio-poker-nos-cassinos",
        name: "Como Funcionam Torneios de Poker nos Cassinos Online?",
        component: () =>
          import(
            "@/views/noticias/child/como-funciona-torneio-poker-nos-cassinos"
          ),
      },
      {
        path: "diferencas-blackjack-online-e-poker",
        name: "Veja as Principais Diferenças entre Blackjack Online e Poker",
        component: () =>
          import("@/views/noticias/child/diferencas-blackjack-online-e-poker"),
      },

      //另一页

      {
        path: "roleta-americana-o-que-e",
        name: "Roleta americana o que é e como funciona | Casinos24",
        component: () =>
          import("@/views/noticias/pagina2/roleta-americana-o-que-e"),
      },
      {
        path: "tipos-de-roleta-de-cassino",
        name: "Conheça os Tipos de Roleta mais comuns nos Cassinos",
        component: () =>
          import("@/views/noticias/pagina2/tipos-de-roleta-de-cassino"),
      },
      {
        path: "o-que-e-um-dealer",
        name: "Entenda O que é um Dealer nos Cassinos Online",
        component: () => import("@/views/noticias/pagina2/o-que-e-um-dealer"),
      },
      {
        path: "principais-termos-siglas-nos-cassinos",
        name: "Principais Termos e Siglas nos Cassinos | Casinos24",
        component: () =>
          import(
            "@/views/noticias/pagina2/principais-termos-siglas-nos-cassinos"
          ),
      },
      {
        path: "slots-com-bonus-de-boas-vindas",
        name: "Slots com Bônus de boas-vindas para novos jogadores",
        component: () =>
          import("@/views/noticias/pagina2/slots-com-bonus-de-boas-vindas"),
      },
      {
        path: "dicas-para-jogar-blackjack",
        name: "Conheça Dicas Para Jogar 21 Blackjack Online",
        component: () =>
          import("@/views/noticias/pagina2/dicas-para-jogar-blackjack"),
      },
      {
        path: "minutos-pagantes-tsar-treasures",
        name: "Minutos Pagantes do Tsar Treasures em 2024",
        component: () =>
          import("@/views/noticias/pagina2/minutos-pagantes-tsar-treasures"),
      },
      {
        path: "minutos-pagantes-santas-gift-rush",
        name: "Minutos Pagantes do Santa's Gift Rush em 2024",
        component: () =>
          import("@/views/noticias/pagina2/minutos-pagantes-santas-gift-rush"),
      },
      {
        path: "minutos-pagantes-buffalo-win",
        name: "Minutos Pagantes do Buffalo Win em 2024",
        component: () =>
          import("@/views/noticias/pagina2/minutos-pagantes-buffalo-win"),
      },
      {
        path: "minutos-pagantes-hip-hop-panda",
        name: "Minutos Pagantes do Hip Hop Panda em 2024",
        component: () =>
          import("@/views/noticias/pagina2/minutos-pagantes-hip-hop-panda"),
      },
      {
        path: "minutos-pagantes-songkran-splash",
        name: "Minutos Pagantes do Songkran Splash em 2024",
        component: () =>
          import("@/views/noticias/pagina2/minutos-pagantes-songkran-splash"),
      },
      {
        path: "minutos-pagantes-bikini-paradise",
        name: "Minutos Pagantes do Bikini Paradise em 2024",
        component: () =>
          import("@/views/noticias/pagina2/minutos-pagantes-bikini-paradise"),
      },
      {
        path: "minutos-pagantes-piggy-gold",
        name: "Minutos Pagantes do Piggy Gold em 2024 [REVELADO]",
        component: () =>
          import("@/views/noticias/pagina2/minutos-pagantes-piggy-gold"),
      },
      {
        path: "minutos-pagantes-fruit-party",
        name: "Minutos Pagantes do Fruit Party em 2024",
        component: () =>
          import("@/views/noticias/pagina2/minutos-pagantes-fruit-party"),
      },
      {
        path: "melhor-horario-para-jogar-lucky-piggy",
        name: "Melhor Horário Para Jogar Lucky Piggy em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-lucky-piggy"
          ),
      },
      {
        path: "jogos-de-azar",
        name: "Jogos de Azar: O que São, Como Funcionam e Dicas",
        component: () => import("@/views/noticias/pagina2/jogos-de-azar"),
      },
      {
        path: "melhor-horario-para-jogar-20-coins",
        name: "Melhor Horário Para Jogar slot 20Coins em 2024",
        component: () =>
          import("@/views/noticias/pagina2/melhor-horario-para-jogar-20-coins"),
      },
      {
        path: "melhor-horario-para-jogar-action-boost-gladiator",
        name: "Melhor Horário Para Jogar Action Boost Gladiator em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-action-boost-gladiator"
          ),
      },
      {
        path: "melhor-horario-para-jogar-wild-fireworks",
        name: "Melhor Horário Para Jogar Wild Fireworks em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-wild-fireworks"
          ),
      },
      {
        path: "minutos-pagantes-wild-bandito",
        name: "Minutos Pagantes do Wild Bandito em 2024 [REVELADO]",
        component: () =>
          import("@/views/noticias/pagina2/minutos-pagantes-wild-bandito"),
      },
      {
        path: "melhor-horario-para-jogar-wild-bandito",
        name: "Melhor Horário Para Jogar Wild Bandito em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-wild-bandito"
          ),
      },
      {
        path: "melhor-horario-para-jogar-tsar-treasures",
        name: "Melhor Horário Para Jogar Tsar Treasures em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-tsar-treasures"
          ),
      },
      {
        path: "melhor-horario-para-jogar-santas-gift-rush",
        name: "Melhor Horário Para Jogar Santa's Gift Rush em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-santas-gift-rush"
          ),
      },
      {
        path: "melhor-horario-para-jogar-buffalo-win",
        name: "Melhor Horário Para Jogar Buffalo Win em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-buffalo-win"
          ),
      },
      {
        path: "melhor-horario-para-jogar-hip-hop-panda",
        name: "Melhor Horário Para Jogar Hip Hop Panda em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-hip-hop-panda"
          ),
      },
      {
        path: "melhor-horario-para-jogar-songkran-splash",
        name: "Melhor Horário Para Jogar Songkran Splash em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-songkran-splash"
          ),
      },
      {
        path: "melhor-horario-para-jogar-bikini-paradise",
        name: "Melhor Horário Para Jogar Bikini Paradise em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-bikini-paradise"
          ),
      },
      {
        path: "melhor-horario-para-jogar-piggy-gold",
        name: "Melhor Horário Para Jogar Piggy Gold a Noite, Tarde, Dia",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-piggy-gold"
          ),
      },
      {
        path: "melhor-horario-para-jogar-fruit-party",
        name: "Melhor Horário Para Jogar Fruit Party a Noite, Tarde, Dia",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-fruit-party"
          ),
      },
      {
        path: "betano-cassino",
        name: "Betano cassino: Saiba quais os jogos presentes e promoções ",
        component: () => import("@/views/noticias/pagina2/betano-cassino"),
      },
      {
        path: "betano-bonus",
        name: "Betano bônus: Saiba como funciona e como sacar seus fundos",
        component: () => import("@/views/noticias/pagina2/betano-bonus"),
      },
      {
        path: "betano-login",
        name: "Betano login: Aprenda Como Acessar o site e fazer apostas",
        component: () => import("@/views/noticias/pagina2/betano-login"),
      },
      {
        path: "blaze-app",
        name: "Blaze App: Como Apostar no Celular, Como Funciona e Mais",
        component: () => import("@/views/noticias/pagina2/blaze-app"),
      },
      {
        path: "aviator-betano",
        name: "Aviator Betano: Bônus, Como Funciona, Como Jogar, Dicas",
        component: () => import("@/views/noticias/pagina2/aviator-betano"),
      },
      {
        path: "aviator-parimatch",
        name: "Aviator Parimatch: Bônus, Como Funciona, Como Jogar, Dicas",
        component: () => import("@/views/noticias/pagina2/aviator-parimatch"),
      },
      {
        path: "dragon-hatch-plataforma-nova",
        name: "5 Plataformas Novas Para Jogar Dragon Hatch em 2024-",
        component: () =>
          import("@/views/noticias/pagina2/dragon-hatch-plataforma-nova"),
      },
      {
        path: "fortune-ox-plataforma-nova",
        name: "5 Plataformas Novas Para Jogar Fortune OX em 2024",
        component: () =>
          import("@/views/noticias/pagina2/fortune-ox-plataforma-nova"),
      },
      {
        path: "fortune-mouse-plataforma-nova",
        name: "5 Plataformas Novas Para Jogar Fortune Mouse em 2024",
        component: () =>
          import("@/views/noticias/pagina2/fortune-mouse-plataforma-nova"),
      },
      {
        path: "fortune-rabbit-plataforma-nova",
        name: "5 Plataformas Novas Para Jogar Fortune Rabbit em 2024",
        component: () =>
          import("@/views/noticias/pagina2/fortune-rabbit-plataforma-nova"),
      },
      {
        path: "fortune-tiger-plataforma-nova",
        name: "5 Plataformas Novas Para Jogar Fortune Tiger em 2024",
        component: () =>
          import("@/views/noticias/pagina2/fortune-tiger-plataforma-nova"),
      },
      {
        path: "melhor-horario-para-jogar-caishen-wins",
        name: "Melhores Horários Para Jogar Caishen Wins em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-caishen-wins"
          ),
      },
      {
        path: "melhor-horario-para-jogar-ganesha-fortune",
        name: "Melhores Horários Para Jogar Ganesha Fortune em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-ganesha-fortune"
          ),
      },
      {
        path: "melhor-horario-para-jogar-ganesha-gold",
        name: "Melhores Horários Para Jogar Ganesha Gold em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-ganesha-gold"
          ),
      },
      {
        path: "melhor-horario-para-jogar-leprechaun-riches",
        name: "Melhores Horários Para Jogar Leprechaun Riches em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-leprechaun-riches"
          ),
      },
      {
        path: "melhor-horario-para-jogar-double-fortune",
        name: "Melhores Horários Para Jogar Double Fortune em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-double-fortune"
          ),
      },
      // {
      //   path: "melhor-horario-para-jogar-tree-of-fortune",
      //   name: "melhor-horario-para-jogar-tree-of-fortune",
      //   component: () =>
      //     import(
      //       "@/views/noticias/pagina2/melhor-horario-para-jogar-tree-of-fortune"
      //     ),
      // },
      {
        path: "melhor-horario-para-jogar-dragon-hero",
        name: "Melhores Horários Para Jogar Dragon Hero em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-dragon-hero"
          ),
      },
      {
        path: "melhor-horario-para-jogar-the-great-icescape",
        name: "Melhores Horários Para Jogar The Great Icescape em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-the-great-icescape"
          ),
      },
      {
        path: "melhor-horario-para-jogar-fortune-gods",
        name: "Melhores Horários Para Jogar Fortune Gods em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-fortune-gods"
          ),
      },
      {
        path: "melhor-horario-para-jogar-midas-fortune",
        name: "Melhores Horários Para Jogar Midas Fortune em 2024",
        component: () =>
          import(
            "@/views/noticias/pagina2/melhor-horario-para-jogar-midas-fortune"
          ),
      },
      // page2 end
      // page5 start
      {
        path: "fortune-tiger-esta-pagando",
        name: "Fortune Tiger está pagando hoje? Realmente Paga em 2024?",
        component: () =>
          import("@/views/noticias/pagina5/fortune-tiger-esta-pagando"),
      },
      {
        path: "sacar-dinheiro-fortune-tiger",
        name: "Como Sacar o Dinheiro que ganhei jogando Fortune Tiger?",
        component: () =>
          import("@/views/noticias/pagina5/sacar-dinheiro-fortune-tiger"),
      },
      {
        path: "fortune-tiger-erro-fora-do-ar",
        name: "Fortune Tiger Está For do Ar? Não Abre? Veja o Que Fazer!",
        component: () =>
          import("@/views/noticias/pagina5/fortune-tiger-erro-fora-do-ar"),
      },
      {
        path: "fortune-tiger-quanto-vale-cada-figura",
        name: "Fortune Tiger Quanto Vale Cada Figura? Quanto Paga?",
        component: () =>
          import(
            "@/views/noticias/pagina5/fortune-tiger-quanto-vale-cada-figura"
          ),
      },
      {
        path: "melhor-horario-para-jogar-fortune-tiger",
        name: "Melhor Horário Para Jogar Fortune Tiger a Noite, Tarde, Dia",
        component: () =>
          import(
            "@/views/noticias/pagina5/melhor-horario-para-jogar-fortune-tiger"
          ),
      },
      {
        path: "fortune-tiger-qual-plataforma-jogar",
        name: "Fortune Tiger: Qual Melhor Plataforma? Onde Jogar em 2023?",
        component: () =>
          import(
            "@/views/noticias/pagina5/fortune-tiger-qual-plataforma-jogar"
          ),
      },
      {
        path: "fortune-tiger-quanto-tempo-demora-para-cair-na-conta",
        name: "Fortune Tiger Quanto Tempo Demora Para Cair na Conta?",
        component: () =>
          import(
            "@/views/noticias/pagina5/fortune-tiger-quanto-tempo-demora-para-cair-na-conta"
          ),
      },
      {
        path: "fortune-tiger-bonus-de-cadastro",
        name: "Fortune Tiger Com Bônus de Cadastro | 3 Melhores Sites",
        component: () =>
          import("@/views/noticias/pagina5/fortune-tiger-bonus-de-cadastro"),
      },
      {
        path: "gale-no-cassino",
        name: "Descubra O Que é Gale nos Cassinos Online",
        component: () => import("@/views/noticias/pagina5/gale-no-cassino"),
      },
      {
        path: "handicap3",
        name: "Handicap +3 | O que é e Como Funciona o Handicap Mais 3",
        component: () => import("@/views/noticias/pagina5/handicap3"),
      },

      {
        path: "",
        name: "Blog de Cassino | Os Melhores Conteúdos Sobre Cassino",
        component: () => import("@/views/noticias/children/pagina1"),
        children: [],
      },
      {
        path: "pagina/2",
        name: "Blog de Cassino | Os Melhores Conteúdos Sobre Cassino",
        component: () => import("@/views/noticias/children/pagina2"),
        children: [],
      },

      {
        path: "/noticias/aviator-parimatch",
        name: "Aviator Parimatch: Bônus, Como Funciona, Como Jogar, Dicas",
        component: () => import("@/views/noticias/children/aviator-parimatch"),
        children: [],
      },

      {
        path: "/noticias/pagina/3",
        name: "Blog de Cassino | Os Melhores Conteúdos Sobre Cassino",
        component: () => import("@/views/noticias/children/pagina3"),
        children: [],
      },
      {
        path: "/noticias/pagina/4",
        name: "Blog de Cassino | Os Melhores Conteúdos Sobre Cassino",
        component: () => import("@/views/noticias/children/pagina4"),
        children: [],
      },
      {
        path: "/noticias/pagina/5",
        name: "Blog de Cassino | Os Melhores Conteúdos Sobre Cassino",
        component: () => import("@/views/noticias/children/pagina5"),
        children: [],
      },
    ],
  },
  // {
  //   path: "/noticias/pagina/5",
  //   name: "pagina5",
  //   component: () => import("@/views/noticias/children/pagina5"),
  //   children: [],
  // },
  {
    path: "/noticias/como-funciona-jackpot",
    name: "Entenda Como funciona o Jackpot Online | Casinos24",
    component: () => import("@/views/noticias/children/como-funciona-jackpot"),
    children: [],
  },
  // {
  //   path: "/noticias/dragon-hatch-deposito-de-20-reais",
  //   name: "dragon-hatch-deposito-de-20-reais",
  //   component: () =>
  //     import("@/views/noticias/children/dragon-hatch-deposito-de-20-reais"),
  //   children: [],
  // },
  // {
  //   path: "/noticias/o-que-sao-jackpots",
  //   name: "o-que-sao-jackpots",
  //   component: () => import("@/views/noticias/children/o-que-sao-jackpots"),
  //   children: [],
  // },
  // {
  //   path: "/noticias/melhores-jogos-de-jackpot",
  //   name: "melhores-jogos-de-jackpot",
  //   component: () =>
  //     import("@/views/noticias/children/melhores-jogos-de-jackpot"),
  //   children: [],
  // },
  // {
  //   path: "/noticias/como-jogar-baccarat",
  //   name: "como-jogar-baccarat",
  //   component: () => import("@/views/noticias/children/como-jogar-baccarat"),
  //   children: [],
  // },
  {
    path: "/noticias/dragon-hatch-deposito-de-20-reais",
    name: "Dragon Hatch Depósito 20 Reais: 5 Sites Para Jogar em 2024",
    component: () =>
      import("@/views/noticias/children/dragon-hatch-deposito-de-20-reais"),
    children: [],
  },
  {
    path: "/noticias/o-que-sao-jackpots",
    name: "O que é Jackpot, Como funciona e Estratégias Para Ganhar",
    component: () => import("@/views/noticias/children/o-que-sao-jackpots"),
    children: [],
  },
  {
    path: "/noticias/melhores-jogos-de-jackpot",
    name: "10 Melhores Jogos de Jackpot em 2024 | Ranking Atualizado",
    component: () =>
      import("@/views/noticias/children/melhores-jogos-de-jackpot"),
    children: [],
  },
  // {
  //     path: "/noticias/como-jogar-baccarat",
  //     name: "como-jogar-baccarat",
  //     component: () => import("@/views/noticias/children/como-jogar-baccarat"),
  //     children: [],
  // },
  {
    path: "/noticias/plataforma-que-mais-paga-fortune-mouse",
    name: "5 Plataformas Que Mais Pagam Dragon Hatch | 2024",
    component: () =>
      import("@/views/noticias/plataforma-que-mais-paga-fortune-mouse"),
    children: [],
  },
  {
    path: "/noticias/plataforma-que-mais-paga-fortune-ox",
    name: "5 Plataformas Que Mais Pagam Fortune OX | Atualizado 2024",
    component: () =>
      import("@/views/noticias/plataforma-que-mais-paga-fortune-ox"),
  },
  {
    path: "/noticias/plataforma-que-mais-paga-fortune-rabbit",
    name: "5 Plataformas Que Mais Pagam Fortune Rabbit | Atualizado",
    component: () =>
      import("@/views/noticias/plataforma-que-mais-paga-fortune-rabbit"),
  },
  {
    path: "/gameshows",
    name: "Gameshows Online: 8 Melhores Sites Para Jogar em 2024",
    component: () => import("@/views/gameshows/gameshows"),
  },
  {
    path: "/gameshows/crazy-time",
    name: "Crazy Time Online | Melhores Sites Para Jogar em 2024",
    component: () => import("@/views/gameshows/children/crazy-time"),
  },
  {
    path: "/gameshows/crazy-coin-flip",
    name: "Crazy Coin Flip Online | Melhores Sites Para Jogar em 2024",
    component: () => import("@/views/gameshows/children/crazy-coin-flip"),
  },
  {
    path: "/gameshows/monopoly-live",
    name: "Monopoly Live Online | Melhores Sites Para Jogar em 2024",
    component: () => import("@/views/gameshows/children/monopoly-live"),
  },
  {
    path: "/gameshows/live-mega-ball",
    name: "Live Mega Ball Online | Melhores Sites Para Jogar em 2024",
    component: () => import("@/views/gameshows/children/live-mega-ball"),
  },
  {
    path: "/cassino-ao-vivo/mega-wheel-online",
    name: "10 Melhores Cassinos Ao Vivo - Roleta, Poker, Blackjack e +",
    component: () =>
      import("@/views/cassino-ao-vivo/children/mega-wheel-online"),
  },
  {
    path: "/jackpots",
    name: "Jackpots Cassino Slots | 10 Melhores Sites Para Jogar",
    component: () => import("@/views/jackpots/jackpots"),
  },
  {
    path: "/megaways",
    name: "Jogar Megaways Caça-Níqueis Slots | Melhores Sites em 2024",
    component: () => import("@/views/megaways/megaways"),
  },
  {
    path: "/cripto-cassinos",
    name: "5 Melhores Cassinos Online que Aceitam Criptomoedas | 2024",
    component: () => import("@/views/cripto-cassinos/cripto-cassinos"),
  },
  {
    path: "/cripto-cassinos/bitcoin",
    name: "5 Melhores Cassinos Online Que Aceitam Bitcoin BTC | 2024",
    component: () => import("@/views/cripto-cassinos/children/bitcoin"),
  },
  {
    path: "/cripto-cassinos/ethereum",
    name: "5 Melhores Cassino Online que Aceitam Ethereum em 2024",
    component: () => import("@/views/cripto-cassinos/children/ethereum"),
  },
  {
    path: "/cripto-cassinos/litecoin",
    name: "5 Melhores Sites de Cassino Que Aceitam Litecoin LTC",
    component: () => import("@/views/cripto-cassinos/children/litecoin"),
  },

  {
    path: "/cripto-cassinos/tether",
    name: "5 Melhores Cassino Online que Aceitam Tether USDT",
    component: () => import("@/views/cripto-cassinos/children/tether"),
  },
  {
    path: "/cripto-cassinos/ripple",
    name: "5 Melhores Sites de Cassino Que Aceitam XRP Ripple",
    component: () => import("@/views/cripto-cassinos/children/ripple"),
  },

  {
    path: "/cassino-ao-vivo/roleta-espanhola-online",
    name: "5 Melhores Sites de Cassino Que Aceitam XRP Ripple",
    component: () =>
      import("@/views/cripto-cassinos/children/roleta-espanhola-online"),
  },

  {
    path: "/apostas",
    name: "8 Melhores Sites de Apostas Confiáveis do Brasil - 2024",
    component: () => import("@/views/apostas/apostas"),
  },
  {
    path: "/apostas/bonus",
    name: "4 Melhores Casas Apostas Com Bônus de Boas-Vindas 2024",
    component: () => import("@/views/apostas/children/bonus"),
  },
  {
    path: "/apostas/sites-de-apostas-que-aceitam-pix",
    name: "Melhores sites de apostas que aceitam Pix em 2024",
    component: () =>
      import("@/views/apostas/children/sites-de-apostas-que-aceitam-pix"),
  },
  {
    path: "/apostas/sites-de-apostas-que-aceitam-boleto",
    name: "Melhores sites de apostas que aceitam boleto em 2024",
    component: () =>
      import("@/views/apostas/children/sites-de-apostas-que-aceitam-boleto"),
  },
  {
    path: "/apostas/sites-de-apostas-que-aceitam-transferencia-bancaria",
    name: "Melhores sites de apostas que aceitam transferência em 2024",
    component: () =>
      import(
        "@/views/apostas/children/sites-de-apostas-que-aceitam-transferencia-bancaria"
      ),
  },
  {
    path: "/apostas/sites-de-apostas-que-aceitam-cartao-de-credito",
    name: "Melhores sites de apostas que aceitam cartão de crédito",
    component: () =>
      import(
        "@/views/apostas/children/sites-de-apostas-que-aceitam-cartao-de-credito"
      ),
  },
  {
    path: "/apostas/sites-de-apostas-com-skrill",
    name: "Melhores sites de apostas que aceitam Skrill em 2024",
    component: () =>
      import("@/views/apostas/children/sites-de-apostas-com-skrill"),
  },
  {
    path: "/apostas/sites-de-apostas-que-aceitam-pay4fun",
    name: "Melhores sites de apostas que aceitam Pay4fun em 2024",
    component: () =>
      import("@/views/apostas/children/sites-de-apostas-que-aceitam-pay4fun"),
  },

  {
    path: "/apostas/5-melhores-sites-de-aposta-com-criptomoedas",
    name: "5 Melhores sites de aposta com criptomoedas em 2024",
    component: () =>
      import(
        "@/views/apostas/children/5-melhores-sites-de-aposta-com-criptomoedas"
      ),
  },

  {
    path: "/apostas/sites-de-apostas-que-aceitam-neteller",
    name: "Melhores sites de apostas que aceitam Neteller",
    component: () =>
      import("@/views/apostas/children/sites-de-apostas-que-aceitam-neteller"),
  },

  {
    path: "/apostas/sites-de-apostas-que-aceitam-astropay",
    name: "Melhores sites de apostas que aceitam Astropay em 2024",
    component: () =>
      import("@/views/apostas/children/sites-de-apostas-que-aceitam-astropay"),
  },

  {
    path: "/metodos-de-pagamentos",
    name: "Principais métodos de pagamentos em cassinos brasileiros",
    component: () =>
      import("@/views/metodos-de-pagamentos/metodos-de-pagamentos"),
  },
  {
    path: "/metodos-de-pagamentos/pix",
    name: "7 Melhores Sites de Cassino Com PIX - Depósito e Saque",
    component: () => import("@/views/metodos-de-pagamentos/children/pix"),
  },
  {
    path: "/metodos-de-pagamentos/pay4fun",
    name: "5 Melhores sites de cassino Com Pay4Fun em 2024",
    component: () => import("@/views/metodos-de-pagamentos/children/pay4fun"),
  },
  {
    path: "/metodos-de-pagamentos/boleto-bancario",
    name: "5 Melhores sites de cassino Com boleto em 2024",
    component: () =>
      import("@/views/metodos-de-pagamentos/children/boleto-bancario"),
  },
  {
    path: "/metodos-de-pagamentos/transferencia-bancaria",
    name: "5 Melhores cassinos com transferência bancária em 2024",
    component: () =>
      import("@/views/metodos-de-pagamentos/children/transferencia-bancaria"),
  },
  {
    path: "/metodos-de-pagamentos/cartao-credito",
    name: "4 Melhores Cassinos Online com Cartão de Crédito em 2024",
    component: () =>
      import("@/views/metodos-de-pagamentos/children/cartao-credito"),
  },
  {
    path: "/metodos-de-pagamentos/neteller",
    name: "5 Melhores sites de cassino Com Neteller em 2024",
    component: () => import("@/views/metodos-de-pagamentos/children/neteller"),
  },

  {
    path: "/cassino-ao-vivo",
    name: "10 Melhores Cassinos Ao Vivo - Roleta, Poker, Blackjack e +",
    component: () => import("@/views/cassino-ao-vivo/cassino-ao-vivo"),
  },
  {
    path: "/cassino-ao-vivo/lightning-dice-online",
    name: "Melhores Cassinos Com Lightning dice Online em 2024",
    component: () =>
      import("@/views/cassino-ao-vivo/children/lightning-dice-online"),
  },
  {
    path: "/cassino-ao-vivo/bac-bo-online",
    name: "Melhores Cassinos Com Bac Bo Online em 2024",
    component: () => import("@/views/cassino-ao-vivo/children/bac-bo-online"),
  },
  {
    path: "/cassino-ao-vivo/baccarat-online",
    name: "8 Melhores Cassinos Com Baccarat Online do Brasil em 2024",
    component: () => import("@/views/cassino-ao-vivo/children/baccarat-online"),
  },
  {
    path: "/cassino-ao-vivo/lucky-ball-roulette-live",
    name: "Melhores Cassinos Com Lucky Ball Roulette em 2024",
    component: () =>
      import("@/views/cassino-ao-vivo/children/lucky-ball-roulette-live"),
  },
  {
    path: "/cassino-ao-vivo/blackjack-online",
    name: "Blackjack Online - Lista com Melhores Cassinos para jogar 21",
    component: () =>
      import("@/views/cassino-ao-vivo/children/blackjack-online"),
  },
  {
    path: "/cassino-ao-vivo/mega-fire-blaze-roulette",
    name: "Mega Fire Blaze Roulette | 5 Melhores Sites Para Jogar 2024",
    component: () =>
      import("@/views/cassino-ao-vivo/children/mega-fire-blaze-roulette"),
  },
  {
    path: "/cassino-ao-vivo/red-door-roulette-online",
    name: "Melhores Cassinos Com Red Door Roulette em 2024",
    component: () =>
      import("@/views/cassino-ao-vivo/children/red-door-roulette-online"),
  },
  {
    path: "/cassino-ao-vivo/roleta-brasileira",
    name: "Roleta Brasileira Online | 8 Melhores Sites Para Jogar 2024",
    component: () =>
      import("@/views/cassino-ao-vivo/children/roleta-brasileira"),
  },
  {
    path: "/cassino-ao-vivo/roleta-relampago",
    name: "Roleta Relâmpago Online | Os Melhores Sites Para Jogar 2024",
    component: () =>
      import("@/views/cassino-ao-vivo/children/roleta-relampago"),
  },
  {
    path: "/cassino-ao-vivo/powerup-roulette-online",
    name: "Melhores Cassinos Com PowerUp Roulette em 2024",
    component: () =>
      import("@/views/cassino-ao-vivo/children/powerup-roulette-online"),
  },
  {
    path: "/cassino-ao-vivo/roleta-americana-online",
    name: "Melhores Cassinos com Roleta Americana Online em 2024",
    component: () =>
      import("@/views/cassino-ao-vivo/children/roleta-americana-online"),
  },
  {
    path: "/cassino-ao-vivo/roleta-europeia-online",
    name: "Melhores Cassinos com Roleta Europeia Online em 2024",
    component: () =>
      import("@/views/cassino-ao-vivo/children/roleta-europeia-online"),
  },
  {
    path: "/cassino-ao-vivo/xxxtreme-lightning-roulette-online",
    name: "Melhores Cassinos Com XXXtreme Lightning Roulette em 2024",
    component: () =>
      import(
        "@/views/cassino-ao-vivo/children/xxxtreme-lightning-roulette-online"
      ),
  },
  {
    path: "/cassino-ao-vivo/lucky-6-roulette-online",
    name: "Melhores Cassinos Com Lucky 6 Roulette em 2024",
    component: () =>
      import("@/views/cassino-ao-vivo/children/lucky-6-roulette-online"),
  },
  {
    path: "/cassino-ao-vivo/power-blackjack-online",
    name: "Melhores Cassinos Com Power Blackjack Online em 2024",
    component: () =>
      import("@/views/cassino-ao-vivo/children/power-blackjack-online"),
  },

  {
    path: "/cassino-ao-vivo/football-studio-online",
    name: "Melhores Cassinos Com Football Studio Online em 2024",
    component: () =>
      import("@/views/cassino-ao-vivo/children/football-studio-online"),
  },

  {
    path: "/cassino-ao-vivo/airwave-roulette-online",
    name: "Melhores Cassinos Com Football Studio Online em 2024",
    component: () =>
      import("@/views/cassino-ao-vivo/children/airwave-roulette-online"),
  },

  {
    path: "/reviews",
    name: "Reviews de Cassino | Melhores Análises de Marcas de Casino",
    component: () => import("@/views/reviews/reviews"),
  },
  {
    path: "/reviews/let-s-go-casino",
    name: "Análise Let´s Go Casino 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/let-s-go-casino"),
  },
  {
    path: "/reviews/joiabet",
    name: "Análise JoiaBet Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/joiabet"),
  },
  {
    path: "/reviews/qbet",
    name: "Análise Qbet Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/qbet"),
  },
  {
    path: "/reviews/pikebit",
    name: "Análise Pikebit Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/pikebit"),
  },
  {
    path: "/reviews/horus-casino",
    name: "Análise Horus Casino 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/horus-casino"),
  },
  {
    path: "/reviews/winz",
    name: "Análise Winz Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/winz"),
  },
  {
    path: "/reviews/kto",
    name: "Análise KTO Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/kto"),
  },
  {
    path: "/reviews/24slots",
    name: "Análise 24Slots Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/24slots"),
  },
  {
    path: "/reviews/casimba",
    name: "Análise Casimba Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/casimba"),
  },
  {
    path: "/reviews/monro-casino",
    name: "Análise Monro Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/monro-casino"),
  },
  {
    path: "/reviews/betano",
    name: "Análise betano Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/betano"),
  },
  {
    path: "/reviews/bc-game",
    name: "Análise BC.Game Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/bc-game"),
  },
  {
    path: "/reviews/brazino-777",
    name: "Análise Brazino777 Brasil 2024: Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/brazino-777"),
  },
  {
    path: "/reviews/megapari",
    name: "Análise Megapari Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/megapari"),
  },
  {
    path: "/reviews/platin-casino",
    name: "Análise Platincasino 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/platin-casino"),
  },
  {
    path: "/reviews/starda",
    name: "Análise Starda Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/starda"),
  },
  {
    path: "/reviews/blaze",
    name: "Análise Blaze Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/blaze"),
  },
  {
    path: "/reviews/smokace",
    name: "Análise SmokAce Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/smokace"),
  },
  {
    path: "/reviews/campeonbet",
    name: "Análise Campeonbet 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/campeonbet"),
  },
  {
    path: "/reviews/depositwin",
    name: "Análise DepositWin 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/depositwin"),
  },
  {
    path: "/reviews/lucky-star",
    name: "Análise Lucky Star 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/lucky-star"),
  },
  {
    path: "/reviews/casigo",
    name: "Análise CasiGO Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/casigo"),
  },
  {
    path: "/reviews/ivibet",
    name: "Análise Ivibet 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/ivibet"),
  },
  {
    path: "/reviews/31bet",
    name: "Análise 31bet 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/31bet"),
  },
  {
    path: "/reviews/apostarei",
    name: "Análise Apostarei Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/apostarei"),
  },
  {
    path: "/reviews/lilibet",
    name: "Análise LiliBet Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/lilibet"),
  },
  {
    path: "/reviews/vegaz-casino",
    name: "Análise Vegaz Casino 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/vegaz-casino"),
  },
  {
    path: "/reviews/rocketpot",
    name: "Análise Rocketpot Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/rocketpot"),
  },
  {
    path: "/reviews/casino",
    name: "Análise Let´s Go Casino 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/casino"),
  },
  {
    path: "/reviews/sapphirebet",
    name: "Análise SapphireBet 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/sapphirebet"),
  },
  {
    path: "/reviews/rivalo",
    name: "Análise Rivalo 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/rivalo"),
  },
  {
    path: "/reviews/casino-estrella",
    name: "Análise Casino Estrella 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/casino-estrella"),
  },
  {
    path: "/reviews/melbet",
    name: "Análise MelBet 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/melbet"),
  },
  {
    path: "/reviews/galera-bet",
    name: "Análise Galera.bet 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/galera-bet"),
  },
  {
    path: "/reviews/wazamba",
    name: "Análise Wazamba Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/wazamba"),
  },
  {
    path: "/reviews/gratowin",
    name: "Análise GratoWin Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/gratowin"),
  },
  {
    path: "/reviews/citobet",
    name: "Análise Citobet Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/citobet"),
  },
  {
    path: "/reviews/bet-o-bet",
    name: "bet-Análise BetoBet 2024: É Confiável? Cadastro e Bônus-bet",
    component: () => import("@/views/reviews/children/bet-o-bet"),
  },
  {
    path: "/reviews/joyaCasino",
    name: "Análise Joya Casino 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/joyaCasino"),
  },
  {
    path: "/reviews/vulkanbet",
    name: "Análise Vulkanbet Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/vulkanbet"),
  },
  {
    path: "/reviews/parimatch",
    name: "Análise Parimatch Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/parimatch"),
  },
  {
    path: "/reviews/stake",
    name: "Análise Stake Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/stake"),
  },
  {
    path: "/reviews/ibet",
    name: "Análise iBet Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/ibet"),
  },
  {
    path: "/reviews/novibet",
    name: "Análise Novibet Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/novibet"),
  },
  {
    path: "/reviews/20bet",
    name: "Análise 20Bet Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/20bet"),
  },
  {
    path: "/reviews/leovegas",
    name: "Análise LeoVegas Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/leovegas"),
  },
  {
    path: "/reviews/22bet",
    name: "Análise 22Bet Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/22bet"),
  },
  {
    path: "/reviews/betsson",
    name: "Análise Betsson Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/betsson"),
  },
  {
    path: "/reviews/jackpotcity-casino",
    name: "Análise JackpotCity Casino: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/jackpotcity-casino"),
  },
  {
    path: "/reviews/lvbet",
    name: "Análise LVBet Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/lvbet"),
  },
  {
    path: "/reviews/windetta",
    name: "Análise Windetta Brasil 2024: É Confiável? Cadastro e Bônus",
    component: () => import("@/views/reviews/children/windetta"),
  },
  {
    path: "/reviews/bet24-7",
    name: "Análise Bet24/7 Brasil 2024: É Confiável? Cadastro e Bônus-7",
    component: () => import("@/views/reviews/children/bet24-7"),
  },
  {
    path: "/sobre-nos",
    name: "Casinos24 - Conheça Um Pouco Mais Sobre Nossa História",
    component: () => import("@/views/sobre-nos/sobre-nos"),
  },
  {
    path: "/jogo-responsavel",
    name: "Casinos24 - Encontre informações sobre jogo responsável",
    component: () => import("@/views/jogo-responsavel/jogo-responsavel"),
  },
  {
    path: "/politica-de-privacidade",
    name: "Casinos24 - Política de Privacidades e Cookies",
    component: () =>
      import("@/views/politica-de-privacidade/politica-de-privacidade"),
  },
  {
    path: "/slots-com-alto-rtp",
    name: "7 Melhores Sites Para Jogar Slots Com Alto RTP em 2024",
    component: () => import("@/views/slots-com-alto-rtp/slots-com-alto-rtp"),
  },
  {
    path: "/provedores",
    name: "10 principais provedores de jogos de slots em cassinos 2024",
    component: () => import("@/views/provedores/provedores"),
  },
  {
    path: "/jogos-pragmatic-play",
    name: "Jogos da Pragmatic Play nos Melhores Cassinos Online",
    component: () => import("@/views/provedores/children/pragmatic-play"),
  },
  {
    path: "/provedores/pragmatic-play",
    name: "Jogos da Pragmatic Play nos Melhores Cassinos Online",
    component: () => import("@/views/provedores/children/pragmatic-play"),
  },
  {
    path: "/provedores/pg-soft",
    name: "Jogos da PG Soft Gaming nos Melhores Cassinos Online",
    component: () => import("@/views/provedores/children/pg-soft"),
  },
  {
    path: "/provedores/pg-soft",
    name: "Jogos da PG Soft Gaming nos Melhores Cassinos Online",
    component: () => import("@/views/provedores/children/pg-soft"),
  },
  {
    path: "/jogos-pg-soft-gaming",
    name: "Jogos da PG Soft Gaming nos Melhores Cassinos Online",
    component: () => import("@/views/provedores/children/jogos-pg-soft-gaming"),
  },
  {
    path: "/noticias/rodadas-gratis-fortune-ox",
    name: "Rodadas grátis no Fortune OX | Melhores Plataformas",
    component: () => import("@/views/noticias/child/rodadas-gratis-fortune-ox"),
  },
  {
    path: "/noticias/rodadas-gratis-fortune-tiger",
    name: "Rodadas grátis no Fortune Tiger | Melhores Plataformas",
    component: () =>
      import("@/views/noticias/child/rodadas-gratis-fortune-tiger"),
  },
  {
    path: "/noticias/fortune-mouse-ganhos",
    name: "Fortune Mouse: Veja os maiores ganhos que já aconteceram",
    component: () => import("@/views/noticias/child/fortune-mouse-ganhos"),
  },
  {
    path: "/noticias/fortune-rabbit-ganhos",
    name: "Fortune Rabbit: Veja os maiores ganhos que já aconteceram",
    component: () => import("@/views/noticias/child/fortune-rabbit-ganhos"),
  },
  {
    path: "/noticias/fortune-ox-ganhos",
    name: "Fortune OX: Veja os maiores ganhos que já aconteceram",
    component: () => import("@/views/noticias/child/fortune-ox-ganhos"),
  },
  {
    path: "/noticias/fortune-tiger-ganhos",
    name: "Fortune Tiger: Veja os maiores ganhos que já aconteceram",
    component: () => import("@/views/noticias/child/fortune-tiger-ganhos"),
  },
  {
    path: "/noticias/dragon-hatch-download",
    name: "Dragon Hatch Download | Cuidado Com Golpes ao Baixar",
    component: () => import("@/views/noticias/child/dragon-hatch-download"),
  },
  {
    path: "/noticias/fortune-mouse-download",
    name: "Fortune Mouse Download | Cuidado Com Golpes ao Baixar",
    component: () => import("@/views/noticias/child/fortune-mouse-download"),
  },
  {
    path: "/noticias/fortune-rabbit-download",
    name: "Fortune Rabbit Download | Cuidado Com Golpes ao Baixa",
    component: () => import("@/views/noticias/child/fortune-rabbit-download"),
  },
  {
    path: "/noticias/fortune-ox-download",
    name: "Fortune OX Download | Cuidado Com Golpes ao Baixar",
    component: () => import("@/views/noticias/child/fortune-ox-download"),
  },
  {
    path: "/noticias/fortune-tiger-download",
    name: "Fortune Tiger Download | Cuidado Com Golpes ao Baixar",
    component: () => import("@/views/noticias/child/fortune-tiger-download"),
  },
  {
    path: "/noticias/link-oficial-dragon-hatch",
    name: "ᐉ Link Confiável Para Jogar Dragon Hatch | Jogo do Dragão",
    component: () => import("@/views/noticias/child/link-oficial-dragon-hatch"),
  },
  {
    path: "/noticias/link-oficial-fortune-mouse",
    name: "ᐉ Link Confiável Para Jogar Fortune Mouse | Jogo do Ratinho",
    component: () =>
      import("@/views/noticias/child/link-oficial-fortune-mouse"),
  },
  {
    path: "/noticias/link-oficial-fortune-rabbit",
    name: "ᐉ Link Confiável Para Jogar Fortune Rabbit | Jogo do Coelho",
    component: () =>
      import("@/views/noticias/child/link-oficial-fortune-rabbit"),
  },
  {
    path: "/noticias/link-oficial-fortune-ox",
    name: "ᐉ Link Confiável Para Jogar Fortune OX | Jogo do Touro",
    component: () => import("@/views/noticias/child/link-oficial-fortune-ox"),
  },
  {
    path: "/noticias/link-oficial-fortune-tiger",
    name: "ᐉ Link Confiável Para Jogar Fortune Tiger | Jogo do Tigre",
    component: () =>
      import("@/views/noticias/child/link-oficial-fortune-tiger"),
  },
  {
    path: "/noticias/dragon-hatch-plataforma-que-paga",
    name: "Dragon Hatch Plataforma que Paga | Conheça as 5 Melhores",
    component: () =>
      import("@/views/noticias/child/dragon-hatch-plataforma-que-paga"),
  },
  {
    path: "/noticias/fortune-mouse-plataforma-que-paga",
    name: "Fortune Mouse Plataforma que Paga | Conheça as 5 Melhores",
    component: () =>
      import("@/views/noticias/child/fortune-mouse-plataforma-que-paga"),
  },

  {
    path: "/noticias/melhor-horario-para-jogar-gates-of-oylmpus",
    name: "Melhores Horários Para Jogar Gates of Olympus em 2024",
    component: () =>
      import(
        "@/views/noticias/child/melhor-horario-para-jogar-gates-of-oylmpus"
      ),
  },
  {
    path: "/noticias/dragon-hatch-vale-a-pena",
    name: "Dragon Hatch vale a pena? Dá mesmo para confiar no jogo?",
    component: () => import("@/views/noticias/child/dragon-hatch-vale-a-pena"),
  },
  {
    path: "/noticias/fortune-mouse-vale-a-pena",
    name: "Fortune Mouse vale a pena? Dá mesmo para confiar no jogo?",
    component: () => import("@/views/noticias/child/fortune-mouse-vale-a-pena"),
  },
  //  13.35
  {
    path: "/noticias/fortune-rabbit-vale-a-pena",
    name: "Fortune Rabbit vale a pena? Dá mesmo para confiar no jogo?",
    component: () =>
      import("@/views/noticias/child/fortune-rabbit-vale-a-pena"),
  },
  {
    path: "/noticias/fortune-tiger-vale-a-pena",
    name: "Fortune Tiger vale a pena? Dá mesmo para confiar no jogo?",
    component: () => import("@/views/noticias/child/fortune-tiger-vale-a-pena"),
  },
  {
    path: "/noticias/dragon-hatch-estrategias",
    name: "5 Melhores Estratégias Para Ganhar no Dragon Hatch | 2024",
    component: () => import("@/views/noticias/child/dragon-hatch-estrategias"),
  },
  {
    path: "/noticias/dragon-hatch-como-sacar",
    name: "Como Sacar o Dinheiro que ganhei jogando Dragon Hatch?",
    component: () => import("@/views/noticias/child/dragon-hatch-como-sacar"),
  },
  {
    path: "/noticias/dragon-hatch-deposito-de-1-real",
    name: "Dragon Hatch Depósito 1 Real: 5 Sites Para Jogar em 2024",
    component: () =>
      import("@/views/noticias/child/dragon-hatch-deposito-de-1-real"),
  },

  {
    path: "/noticias/dragon-hatch-deposito-de-5-reais",
    name: "Dragon Hatch Depósito 5 Reais: 5 Sites Para Jogar em 2024",
    component: () =>
      import("@/views/noticias/child/dragon-hatch-deposito-de-5-reais"),
  },
  {
    path: "/noticias/como-jogar-dragon-hatch-no-celular",
    name: "Como jogar Dragon Hatch no celular em 2024? Passo a Passo",
    component: () =>
      import("@/views/noticias/child/como-jogar-dragon-hatch-no-celular"),
  },
  {
    path: "/noticias/dragon-hatch-nao-consigo-sacar",
    name: "Dragon Hatch Não Consigo Sacar, o que pode ser? Atualizado!",
    component: () =>
      import("@/views/noticias/child/dragon-hatch-nao-consigo-sacar"),
  },
  {
    path: "/noticias/dragon-hatch-parou-de-pagar",
    name: "Dragon Hatch Parou de Pagar em 2024? Segredo Revelado!",
    component: () =>
      import("@/views/noticias/child/dragon-hatch-parou-de-pagar"),
  },
  {
    path: "/noticias/dragon-hatch-tem-na-playstore",
    name: "Será Que o Dragon Hatch Tem na Playstore? CUIDADO!",
    component: () =>
      import("@/views/noticias/child/dragon-hatch-tem-na-playstore"),
  },
  {
    path: "/noticias/dragon-hatch-esta-pagando",
    name: "Dragon Hatch está pagando hoje? Realmente Paga em 2024?",
    component: () => import("@/views/noticias/child/dragon-hatch-esta-pagando"),
  },
  {
    path: "/noticias/dragon-hatch-esta-fora-do-ar",
    name: "Dragon Hatch Está For do Ar? Não Abre? Veja o Que Fazer!",
    component: () =>
      import("@/views/noticias/child/dragon-hatch-esta-fora-do-ar"),
  },
  {
    path: "/noticias/dragon-hatch-quanto-vale-cada-figura",
    name: "Dragon Hatch Quanto Vale Cada Figura? Quanto Paga?",
    component: () =>
      import("@/views/noticias/child/dragon-hatch-quanto-vale-cada-figura"),
  },
  {
    path: "/noticias/dragon-hatch-qual-plataforma-onde-jogar",
    name: "Dragon Hatch: Qual Melhor Plataforma? Onde Jogar em 2024?",
    component: () =>
      import("@/views/noticias/child/dragon-hatch-qual-plataforma-onde-jogar"),
  },
  {
    path: "/noticias/dragon-hatch-quanto-tempo-demora-para-cair-na-conta",
    name: "Dragon Hatch Quanto Tempo Demora Para Cair na Conta?",
    component: () =>
      import(
        "@/views/noticias/child/dragon-hatch-quanto-tempo-demora-para-cair-na-conta"
      ),
  },
  {
    path: "/noticias/dragon-hatch-bonus-de-cadastro",
    name: "Dragon Hatch Com Bônus de Cadastro | 3 Melhores Sites",
    component: () =>
      import("@/views/noticias/child/dragon-hatch-bonus-de-cadastro"),
  },

  {
    path: "/noticias/fortune-ox-estrategias",
    name: " 5 Melhores Estratégias Para Ganhar no Fortune OX | 2024",
    component: () => import("@/views/noticias/child/fortune-ox-estrategias"),
  },
  {
    path: "/noticias/fortune-ox-como-sacar",
    name: "Como Sacar o Dinheiro que ganhei jogando Fortune OX?",
    component: () => import("@/views/noticias/child/fortune-ox-como-sacar"),
  },
  {
    path: "/noticias/fortune-ox-deposito-de-1-real",
    name: "Fortune OX Depósito 1 Real: 5 Sites Para Jogar em 202",
    component: () =>
      import("@/views/noticias/child/fortune-ox-deposito-de-1-real"),
  },
  {
    path: "/noticias/fortune-ox-deposito-de-5-reais",
    name: "Fortune OX Depósito 5 Reais: 5 Sites Para Jogar em 2024",
    component: () =>
      import("@/views/noticias/child/fortune-ox-deposito-de-5-reais.vue"),
  },
  {
    path: "/noticias/como-jogar-fortune-ox-no-celular",
    name: "Como jogar Fortune OX no celular em 2024? Passo a Passo",
    component: () =>
      import("@/views/noticias/child/como-jogar-fortune-ox-no-celular"),
  },
  {
    path: "/noticias/fortune-ox-nao-consigo-sacar",
    name: "Fortune OX Não Consigo Sacar, o que pode ser? Atualizado!",
    component: () =>
      import("@/views/noticias/child/fortune-ox-nao-consigo-sacar"),
  },
  {
    path: "/noticias/fortune-ox-parou-de-pagar",
    name: "Fortune OX Parou de Pagar em 2024? Segredo Revelado!",
    component: () => import("@/views/noticias/child/fortune-ox-parou-de-pagar"),
  },
  {
    path: "/noticias/fortune-ox-tem-na-playstore",
    name: "Será Que o Fortune OX Tem na Playstore? CUIDADO!",
    component: () =>
      import("@/views/noticias/child/fortune-ox-tem-na-playstore"),
  },
  {
    path: "/noticias/fortune-ox-esta-pagando",
    name: "Fortune OX está pagando hoje? Realmente Paga em 2024?",
    component: () => import("@/views/noticias/child/fortune-ox-esta-pagando"),
  },
  {
    path: "/noticias/fortune-ox-esta-fora-do-ar",
    name: "Fortune OX Está For do Ar? Não Abre? Veja o Que Fazer!",
    component: () =>
      import("@/views/noticias/child/fortune-ox-esta-fora-do-ar"),
  },

  {
    path: "/noticias/fortune-ox-quanto-vale-cada-figura",
    name: "Fortune OX Quanto Vale Cada Figura? Quanto Paga?",
    component: () =>
      import("@/views/noticias/child/fortune-ox-quanto-vale-cada-figura"),
  },

  //    ---------------
  {
    path: "/noticias/fortune-ox-qual-plataforma-onde-jogar",
    name: "Fortune OX: Qual Melhor Plataforma? Onde Jogar em 2024?",
    component: () =>
      import("@/views/noticias/child/fortune-ox-qual-plataforma-onde-jogar"),
  },
  {
    path: "/noticias/fortune-ox-quanto-tempo-demora-para-cair-na-conta",
    name: "Fortune OX Quanto Tempo Demora Para Cair na Conta?",
    component: () =>
      import(
        "@/views/noticias/child/fortune-ox-quanto-tempo-demora-para-cair-na-conta"
      ),
  },
  {
    path: "/noticias/fortune-ox-bonus-de-cadastro",
    name: "Fortune OX Com Bônus de Cadastro | 3 Melhores Sites",
    component: () =>
      import("@/views/noticias/child/fortune-ox-bonus-de-cadastro"),
  },
  {
    path: "/noticias/fortune-mouse-estrategias",
    name: "5 Melhores Estratégias Para Ganhar no Fortune Mouse | 2024",
    component: () => import("@/views/noticias/child/fortune-mouse-estrategias"),
  },
  //==================
  {
    path: "/noticias/fortune-mouse-como-sacar",
    name: "Como Sacar o Dinheiro que ganhei jogando Fortune Mouse?",
    component: () => import("@/views/noticias/child/fortune-mouse-como-sacar"),
  },
  {
    path: "/noticias/fortune-mouse-deposito-de-1-real",
    name: "Fortune Mouse Depósito 1 Real: 5 Sites Para Jogar em 2024",
    component: () =>
      import("@/views/noticias/child/fortune-mouse-deposito-de-1-real"),
  },
  {
    path: "/noticias/fortune-mouse-deposito-de-5-reais",
    name: "Fortune Mouse Depósito 5 Reais: 5 Sites Para Jogar em 2024",
    component: () =>
      import("@/views/noticias/child/fortune-mouse-deposito-de-5-reais"),
  },
  {
    path: "/noticias/como-jogar-fortune-mouse-no-celular",
    name: "Como jogar Fortune Mouse no celular em 2024? Passo a Passo",
    component: () =>
      import("@/views/noticias/child/como-jogar-fortune-mouse-no-celular"),
  },

  // {
  //   path: "/noticias/como-jogar-fortune-mouse-no-celular",
  //   name: "como-jogar-fortune-mouse-no-celular",
  //   component: () =>
  //     import("@/views/noticias/child/como-jogar-fortune-mouse-no-celular"),
  // },
  {
    path: "/noticias/fortune-mouse-nao-consigo-sacar",
    name: "Fortune Mouse Não Consigo Sacar, o que pode ser? Atualizado!",
    component: () =>
      import("@/views/noticias/child/fortune-mouse-nao-consigo-sacar"),
  },
  {
    path: "/noticias/fortune-mouse-parou-de-pagar",
    name: "Fortune Mouse Parou de Pagar em 2024? Segredo Revelado!",
    component: () =>
      import("@/views/noticias/child/fortune-mouse-parou-de-pagar"),
  },
  {
    path: "/noticias/fortune-mouse-tem-na-playstore",
    name: "Será Que o Fortune Mouse Tem na Playstore? CUIDADO!",
    component: () =>
      import("@/views/noticias/child/fortune-mouse-tem-na-playstore"),
  },
  {
    path: "/noticias/fortune-mouse-esta-pagando",
    name: "Fortune Mouse está pagando hoje? Realmente Paga em 2024?",
    component: () =>
      import("@/views/noticias/child/fortune-mouse-esta-pagando"),
  },

  {
    path: "/noticias/fortune-mouse-esta-fora-do-ar",
    name: "Fortune Mouse Está For do Ar? Não Abre? Veja o Que Fazer!",
    component: () =>
      import("@/views/noticias/child/fortune-mouse-esta-fora-do-ar"),
  },
  {
    path: "/noticias/fortune-mouse-quanto-vale-cada-figura",
    name: "Fortune Mouse Quanto Vale Cada Figura? Quanto Paga?",
    component: () =>
      import("@/views/noticias/child/fortune-mouse-quanto-vale-cada-figura"),
  },
  {
    path: "/noticias/fortune-mouse-qual-plataforma-onde-jogar",
    name: "Fortune Mouse: Qual Melhor Plataforma? Onde Jogar em 2024?",
    component: () =>
      import("@/views/noticias/child/fortune-mouse-qual-plataforma-onde-jogar"),
  },
  {
    path: "/noticias/fortune-mouse-quanto-tempo-demora-para-cair-na-conta",
    name: "Fortune Mouse Quanto Tempo Demora Para Cair na Conta?",
    component: () =>
      import(
        "@/views/noticias/child/fortune-mouse-quanto-tempo-demora-para-cair-na-conta"
      ),
  },

  {
    path: "/noticias/fortune-mouse-bonus-de-cadastro",
    name: "Fortune Mouse Com Bônus de Cadastro | 3 Melhores Sites",
    component: () =>
      import("@/views/noticias/child/fortune-mouse-bonus-de-cadastro"),
  },
  {
    path: "/noticias/fortune-rabbit-estrategias",
    name: "5 Melhores Estratégias Para Ganhar no Fortune Rabbit | 2024",
    component: () =>
      import("@/views/noticias/child/fortune-rabbit-estrategias"),
  },
  {
    path: "/noticias/fortune-rabbit-como-sacar",
    name: "Como Sacar o Dinheiro que ganhei jogando Fortune Rabbit?",
    component: () => import("@/views/noticias/child/fortune-rabbit-como-sacar"),
  },
  {
    path: "/noticias/fortune-rabbit-deposito-de-1-real",
    name: "Fortune Rabbit Depósito 1 Real: 5 Sites Para Jogar em 2024",
    component: () =>
      import("@/views/noticias/child/fortune-rabbit-deposito-de-1-real"),
  },
  ,
  ,
  // {
  //   path: "/noticias/melhor-horario-para-jogar-tree-of-fortune",
  //   name: "melhor-horario-para-jogar-tree-of-fortune",
  //   component: () =>
  //     import(
  //       "@/views/noticias/child/noticias/melhor-horario-para-jogar-tree-of-fortune"
  //     ),
  // },
  {
    path: "/noticias/melhor-horario-para-jogar-dragon-hero",
    name: "Melhores Horários Para Jogar Dragon Hero em 2024",
    component: () =>
      import("@/views/noticias/child/melhor-horario-para-jogar-dragon-hero"),
  },
  {
    path: "/noticias/melhor-horario-para-jogar-midas-fortune",
    name: "Melhores Horários Para Jogar Midas Fortune em 2024",
    component: () =>
      import("@/views/noticias/child/melhor-horario-para-jogar-midas-fortune"),
  },
  {
    path: "/noticias/melhor-horario-para-jogar-fortune-gods",
    name: "Melhores Horários Para Jogar Fortune Gods em 2024",
    component: () =>
      import("@/views/noticias/child/melhor-horario-para-jogar-fortune-gods"),
  },
  {
    path: "/noticias/melhor-horario-para-jogar-tree-of-fortune",
    name: "Melhores Horários Para Jogar Tree of Fortune em 2024",
    component: () =>
      import(
        "@/views/noticias/child/melhor-horario-para-jogar-tree-of-fortune"
      ),
  },
];
//禁止修改
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  // 检查localStorage中是否已有ipconfig

  // 如果已经确认不是中国IP，设置页面标题并继续导航
  if (to.name) {
    document.title = `${to.name}`;
    next();
  } else if (to.path == "/") {
    document.title = "Casinos24 - Análise, conteúdos e dicas sobre os cassinos";
    next();
  }
});
// router.beforeEach((to, from, next) => {
//   // 检查localStorage中是否已有ipconfig
//   const ipConfig = sessionStorage.getItem("ipconfig");
//
//   if (ipConfig == "true") {
//     // 如果已经确认是中国IP，且当前不在404页面，则重定向到指定页面
//     if (to.path != "/ProhibitAccess") {
//       next("/ProhibitAccess");
//     } else {
//       next(); // 防止循环重定向
//     }
//   } else if (ipConfig == "false") {
//     // 如果已经确认不是中国IP，设置页面标题并继续导航
//     if (to.name) {
//       document.title = `${to.name}`;
//     } else if (to.path == "/") {
//       document.title =
//         "Casinos24 - Análise, conteúdos e dicas sobre os cassinos";
//     }
//     next();
//   } else {
//     // 如果还没有检查IP，则获取并检查
//     axios
//       .get("https://api.ipify.org?format=json")
//       .then((response) => {
//         const ip = response.data.ip;
//         return axios.get(`https://ipapi.co/${ip}/json/`);
//       })
//       .then((locationResponse) => {
//         if (locationResponse.data.country_name == "China") {
//           sessionStorage.setItem("ipconfig", "true");
//           if (to.path !== "/ProhibitAccess") {
//             next("/ProhibitAccess");
//           } else {
//             next();
//           }
//         } else {
//           sessionStorage.setItem("ipconfig", "false");
//           if (to.name) {
//             document.title = `${to.name}`;
//           } else if (to.path == "/") {
//             document.title =
//               "Casinos24 - Análise, conteúdos e dicas sobre os cassinos";
//           }
//           next();
//         }
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//         next();
//       });
//   }
// });
export default router;
