<template>
  <div class="sticky top-0 z-50 bg-navigation-green">
    <div class="mx-auto px-4">
      <div
          class="mx-auto flex max-w-6xl items-center justify-between py-3 lg:justify-start lg:space-x-10 lg:px-8 lg:py-5"
      >
        <div class="flex items-center justify-start lg:w-0 lg:flex-1">
          <a href="/"
          ><span class="sr-only">Casinos24</span
          ><img
              alt="Casinos24 logo"
              data-nimg="1"
              decoding="async"
              fetchpriority="high"
              height="28"
              src="@/assets/images/logo.webp"
              style="color: transparent"
              width="65"
          /></a>
        </div>
        <nav aria-label="Main menu" class="hidden lg:block">
          <ul class="flex space-x-8">
            <router-link
                v-for="(item, index) in titles"
                :key="index"
                :class="active == index ? 'active' : ''"
                :to="item.href"
                class="inline-flex items-center font-roboto text-base font-medium text-white hover:text-primary aria-[current]:text-primary"
                @click="active = index"
            >{{ item.title }}
            </router-link
            >
            <!-- <a class="inline-flex items-center font-roboto text-base font-medium text-white hover:text-primary aria-[current]:text-primary"
              href="/bonus">Bônus de cassino</a>
            <a class="inline-flex items-center font-roboto text-base font-medium text-white hover:text-primary aria-[current]:text-primary"
              href="/slots">Slots</a>
            <a class="inline-flex items-center font-roboto text-base font-medium text-white hover:text-primary aria-[current]:text-primary"
              href="/crash">Crash Games</a>
            <a class="hover:primary inline-flex items-center font-roboto text-base font-medium text-white aria-[current]:text-primary"
              href="/noticias">Notícias</a> -->
          </ul>
        </nav>
        <div class="-my-2 flex">
          <button
              class="flex bg-normal px-2 py-2 mx-2 rounded-full text-white items-center cursor-pointer z-50 ml-auto hover:bg-affiliate-button-hover"
              @click="openSearchModal"
          >
            <span class="sr-only">Open search</span
            >
            <svg
                v-if="!searchModalBol"
                aria-hidden="true"
                class="h-6 w-6 text-white z-30 flex items-center cursor-pointer"
                fill="none"
                height="24"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" x2="16.65" y1="21" y2="16.65"></line>
            </svg>
            <svg
                v-else
                aria-hidden="true"
                class="h-6 w-6"
                fill="none"
                height="24"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="18" x2="6" y1="6" y2="18"></line>
              <line x1="6" x2="18" y1="6" y2="18"></line>
            </svg>
          </button
          >
          <button
              class="flex items-center gap-3 outline-none ring-0"
              type="button"
              @click="openLeftDrawer"
          >
            <span class="sr-only">Open menu</span
            >
            <svg
                aria-hidden="true"
                class="h-7 w-7 text-white"
                fill="none"
                height="24"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="3" x2="21" y1="12" y2="12"></line>
              <line x1="3" x2="21" y1="6" y2="6"></line>
              <line x1="3" x2="21" y1="18" y2="18"></line>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <SearchModal
        v-if="searchModalBol"
        @closeSearchModal="closeSearchModal"
    ></SearchModal>
    <LeftDrawer v-if="leftDrawer" @openLeftDrawer="openLeftDrawer"></LeftDrawer>
  </div>
</template>

<script>
import SearchModal from "@/components/SearchModal.vue";
import LeftDrawer from "@/components/LeftDrawer.vue";

export default {
  name: "Header",
  data() {
    return {
      searchModalBol: false,
      leftDrawer: false,
      titles: [
        {
          title: "Melhores Cassinos Online",
          href: "/melhores-cassinos-online",
        },
        {title: "Bônus de cassino", href: "/bonus"},
        {title: "Slots", href: "/slots"},
        {title: "Crash Games", href: "/crash"},
        {title: "Notícias", href: "/noticias"},
      ],
      active: -1,
    };
  },
  components: {
    LeftDrawer,
    SearchModal,
  },
  methods: {
    openSearchModal() {
      this.searchModalBol = !this.searchModalBol;
    },
    closeSearchModal() {
      this.searchModalBol = false;
    },
    openLeftDrawer() {
      this.leftDrawer = !this.leftDrawer;
    },
  },
};
</script>

<style scoped>
.mx-auto .active {
  color: rgb(255, 213, 0) !important;
}
</style>
